import { Box } from '@mui/material'
import { SendBirdProvider } from '@sendbird/uikit-react'
import * as Sentry from '@sentry/react'
import { useSnackbar } from 'notistack'
import { useEffect, useRef } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ForgotPassword } from 'src/screens/Auth/ForgotPassword'
import { Login } from 'src/screens/Auth/Login'
import { ResetPassword } from 'src/screens/Auth/ResetPassword'

import { client } from '../apollo'
import { BaseContainer } from '../components/BaseContainer'
import { Spinner } from '../components/Spinner'
import {
  selectError,
  selectIsLoggedIn,
  selectLoading,
  selectSendbirdToken,
  selectUserId,
} from '../store/selectors/auth'
import { useAppSelector } from '../store/useAppSelector'
import { AuthenticatedRoutes } from './AuthenticatedRoutes'
import { themeColorSet } from './sendbirdTheme'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const AppRoutes = () => {
  const loading = useAppSelector(selectLoading)
  const originalPath = useRef(window.location.pathname)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const userId = useAppSelector(selectUserId)
  const sendbirdToken = useAppSelector(selectSendbirdToken)
  const sendbirdUserId = `dashboard_${userId}`
  const error = useAppSelector(selectError)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (error)
      enqueueSnackbar(error, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      })
  }, [error, enqueueSnackbar])

  useEffect(() => {
    if (!isLoggedIn) client.clearStore()
  }, [isLoggedIn])

  if (loading)
    return (
      <Box left="50%" top="50%" position="absolute">
        <Spinner />
      </Box>
    )

  return isLoggedIn ? (
    <SendBirdProvider
      appId={import.meta.env.VITE_SENDBIRD_APP_ID ?? ''}
      userId={sendbirdUserId}
      accessToken={sendbirdToken?.token}
      theme="light"
      colorSet={themeColorSet}>
      <BaseContainer>
        <AuthenticatedRoutes originalPath={originalPath.current} />
      </BaseContainer>
    </SendBirdProvider>
  ) : (
    <SentryRoutes>
      {/* Unauthenticated Routes */}
      <Route element={<BaseContainer />}>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route path="*" element={<Navigate to="login" />} />
      </Route>
    </SentryRoutes>
  )
}
