import { Box, useTheme } from '@mui/material'
import { ChildrenProp } from 'react'

interface Props {
  children: ChildrenProp
}

export const PageContainer = ({ children }: Props) => {
  const theme = useTheme()

  return (
    <Box
      overflow="auto"
      flex={1}
      display="flex"
      flexDirection="column"
      padding={theme.spacing(2, 2, 1, 2)}>
      {children}
    </Box>
  )
}
