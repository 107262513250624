import { ChildrenProp, createContext, useContext } from 'react'
import {
  Order,
  PeerPortalMembersQuery,
  QueryMembersOrderBy,
} from 'src/gql/graphql'

import { Header } from './types'
import { usePeerPortalMembers } from './usePeerPortalMembers'

interface MembersContext {
  headers: Header[]
  members: PeerPortalMembersQuery['queryMembers']['membersV2']
  loading: boolean
  totalCount: number
  page: number
  rowsPerPage: number
  order?: Order
  orderBy?: QueryMembersOrderBy
}

const MembersContext = createContext({} as MembersContext)

export const MembersProvider = ({ children }: { children: ChildrenProp }) => {
  const { members, loading, totalCount, order, orderBy, rowsPerPage, page } =
    usePeerPortalMembers()

  const headers: Header[] = [
    { key: 'name', label: 'Name', sort: QueryMembersOrderBy.Name },
    {
      key: 'benefitsActivatedAt',
      label: 'Benefits Activated',
      sort: QueryMembersOrderBy.BenefitsActivatedAt,
    },
    { key: 'peerSupportQuestionnaireTaken', label: 'PSQ Complete?' },
    { key: 'peerAssignment', label: 'Peer' },
    {
      key: 'contractName',
      label: 'Contract',
      sort: QueryMembersOrderBy.ContractName,
    },
    {
      key: 'maxPotentialRewards',
      label: 'Max Potential Rewards',
      sort: QueryMembersOrderBy.MaxPotentialRewards,
    },
    { key: 'lastCheckIn', label: 'Last Check In' },
    {
      key: 'messagingEndsAt',
      label: 'Message End',
      sort: QueryMembersOrderBy.MessagingEndsAt,
    },
    {
      key: 'messageCount',
      label: 'Messages Sent',
      sort: QueryMembersOrderBy.MessageCount,
    },
    {
      key: 'lastMessageSentAt',
      label: 'Last Message Sent At',
      sort: QueryMembersOrderBy.LastMessageSent,
    },
    {
      key: 'lastMessageSentBy',
      label: 'Last Message Sent By',
      sort: QueryMembersOrderBy.LastMessageSentBy,
    },
    {
      key: 'lastMessageReadAt',
      label: 'Last Message Read At',
      sort: QueryMembersOrderBy.LastMessageRead,
    },
    { key: 'wellnessPlanComplete', label: 'Wellness Plan Complete?' },
    { key: 'totalEngagementDuration', label: 'Total Engagement Time' },
    { key: 'okToContact', label: 'Okay To Contact' },
    {
      key: 'language',
      label: 'Language',
      sort: QueryMembersOrderBy.Language,
    },
  ]

  const value = {
    headers,
    members,
    loading,
    totalCount,
    order,
    orderBy,
    rowsPerPage,
    page,
  }

  return (
    <MembersContext.Provider value={value}>{children}</MembersContext.Provider>
  )
}

export const useMembersContext = () => useContext(MembersContext)
