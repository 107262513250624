import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { graphql } from 'src/gql'
import { clearSelectedChannel } from 'src/store/slices/messaging'
import { useAppDispatch } from 'src/store/useAppDispatch'

const AddToChannelMutation = graphql(`
  mutation AddToChannel($url: String!) {
    addDashboardUserToChannel(url: $url) {
      success
      message
      url
    }
  }
`)

export const useAddToChannel = () => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [mutate] = useMutation(AddToChannelMutation, {
    onCompleted: ({ addDashboardUserToChannel: { success, url, message } }) => {
      if (success && url) return

      dispatch(clearSelectedChannel())
      enqueueSnackbar(message, { variant: 'error' })
    },
  })

  return {
    addToChannel: async (url: string) => mutate({ variables: { url } }),
  }
}
