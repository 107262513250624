import { createSlice } from '@reduxjs/toolkit'

const testMode = createSlice({
  name: 'testMode',
  initialState: false,
  reducers: {
    toggleTestMode: state => {
      state = !state
      return state
    },
  },
})

export const { toggleTestMode } = testMode.actions
export const { reducer } = testMode
