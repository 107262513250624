import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { uniq, without } from 'lodash'

interface MultiselectState {
  'peerPortal.members': number[]
  'tigers.codes': number[]
  'tigers.members': number[]
}

export type MultiselectKey = keyof MultiselectState

const multiselect = createSlice({
  name: 'multiselect',
  initialState: {
    'peerPortal.members': [],
    'tigers.codes': [],
    'tigers.members': [],
  } as MultiselectState,
  reducers: {
    onSelect: (
      state,
      { payload }: PayloadAction<{ ids: number[]; key: MultiselectKey }>,
    ) => {
      state[payload.key] = uniq([...state[payload.key], ...payload.ids])
    },
    onDeselect: (
      state,
      { payload }: PayloadAction<{ ids: number[]; key: MultiselectKey }>,
    ) => {
      state[payload.key] = without(state[payload.key], ...payload.ids)
    },
    clearSelections: (state, { payload }: PayloadAction<MultiselectKey>) => {
      state[payload] = []
    },
  },
})

export const { clearSelections, onSelect, onDeselect } = multiselect.actions

export const { reducer } = multiselect
