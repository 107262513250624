import { throttle } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { store } from 'src/store'
import { selectIsLoggedIn } from 'src/store/selectors/auth'
import { logout } from 'src/store/thunks/auth'
import { useAppDispatch } from 'src/store/useAppDispatch'

let timer: NodeJS.Timeout

// Manually expire tokens after 15m of inactivity
export const INACTIVITY_INTERVAL =
  Number(import.meta.env.VITE_INACTIVITY_INTERVAL) || 15 * 60 * 1000

// Only check for new signs of activity 30s after the last
export const ACTIVITY_THROTTLE_INTERVAL =
  Number(import.meta.env.VITE_ACTIVITY_THROTTLE_INTERVAL) || 30 * 1000

export const useLogoutOnInactivity = () => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const resetTimer = throttle(() => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        if (!selectIsLoggedIn(store.getState())) return
        dispatch(logout())
        enqueueSnackbar(
          "It looks like you've been inactive for a while - please log in again to continue",
          { variant: 'warning' },
        )
      }, INACTIVITY_INTERVAL)
    }, ACTIVITY_THROTTLE_INTERVAL)

    window.addEventListener('mousemove', resetTimer)
    window.addEventListener('mousedown', resetTimer)
    window.addEventListener('keypress', resetTimer)
    window.addEventListener('focus', resetTimer)
  }, [dispatch, enqueueSnackbar])
}
