import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MessagingState } from 'src/types'

import { logout } from '../thunks/auth'

const initialState: MessagingState = {
  loading: true,
  selectedChannelUrl: undefined,
}

export const messagingSlice = createSlice({
  name: 'messaging',
  initialState,
  reducers: {
    setSelectedChannel: (state, { payload }: PayloadAction<string>) => {
      state.selectedChannelUrl = payload
    },
    clearSelectedChannel: state => {
      state.selectedChannelUrl = undefined
    },
  },
  extraReducers: builder => {
    builder.addCase(logout.fulfilled, (state, { payload: _ }) => {
      state.selectedChannelUrl = undefined
    })
  },
})

export const { setSelectedChannel, clearSelectedChannel } =
  messagingSlice.actions

export const { reducer } = messagingSlice
