import { yupResolver } from '@hookform/resolvers/yup'
import { Check, Close, Edit } from '@mui/icons-material'
import { Box, FormControl, IconButton, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import ReactMarkdown from 'react-markdown'
import { TigersMemberShowQuery } from 'src/gql/graphql'
import * as yup from 'yup'

import { useUpdateMember } from './useUpdateMember'

interface Props {
  member: TigersMemberShowQuery['findMember']
}

interface NotesFormValues {
  csNotes: string | null
}

export const Notes: React.FC<Props> = ({ member }) => {
  const [isEditing, setEditing] = useState<boolean>(false)
  const { updateNotes } = useUpdateMember()

  const defaultValues = { csNotes: member?.csNotes ?? null }

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<NotesFormValues>({ defaultValues, resolver: yupResolver(schema) })

  const resetForm = () => {
    reset(defaultValues)
    setEditing(false)
  }

  useEffect(() => {
    reset({ csNotes: member?.csNotes ?? null })
  }, [member?.csNotes, reset])

  if (!member) return null

  const onSubmit = ({ csNotes }: NotesFormValues) => {
    updateNotes({ csNotes, memberId: member?.id })
    resetForm()
  }

  return (
    <>
      {isEditing ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display="flex"
            flexDirection="column"
            marginY={t => t.spacing(2)}>
            <FormControl fullWidth>
              <TextField
                {...register('csNotes')}
                aria-label="Edit Notes"
                multiline
                rows="8"
                placeholder="Add notes"
                error={!!errors.csNotes}
                helperText={errors.csNotes?.message}
              />
            </FormControl>
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                type="submit"
                disabled={isSubmitting}
                title="Confirm"
                size="small">
                <Check htmlColor="green" />
              </IconButton>
              <IconButton title="Reset" onClick={resetForm} size="small">
                <Close htmlColor="red" />
              </IconButton>
            </Box>
          </Box>
        </form>
      ) : (
        <Box marginY={t => t.spacing(2)} display="flex">
          <Box flexGrow={1}>
            <ReactMarkdown>{member.csNotes ?? ''}</ReactMarkdown>
          </Box>
          <Box>
            <IconButton
              title="Edit"
              onClick={() => setEditing(true)}
              size="small">
              <Edit />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  )
}

const schema = yup.object().shape({ notes: yup.string().nullable().trim() })
