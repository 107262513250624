import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid, Link, TextField } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { AuthForm } from './AuthForm'
import { useResetPassword } from './useResetPassword'

const schema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
})

export const ForgotPassword: React.FC = () => {
  const navigate = useNavigate()
  const { requestResetPassword } = useResetPassword()
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<{ email: string }>({
    defaultValues: { email: '' },
    resolver: yupResolver(schema),
  })

  return (
    <AuthForm onSubmit={handleSubmit(requestResetPassword)}>
      <TextField
        {...register('email')}
        type="email"
        label="Email Address"
        variant="outlined"
        fullWidth
        autoFocus
        autoComplete="email"
        error={!!errors.email}
        helperText={errors.email?.message}
        required
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        sx={{ margin: t => t.spacing(2, 0) }}
        disabled={isSubmitting}>
        {isSubmitting ? 'Sending link...' : 'Send reset password link'}
      </Button>
      <Grid container>
        <Grid item xs>
          <Link href="#" onClick={() => navigate(`/login`)} variant="body2">
            &larr; Back to sign in
          </Link>
        </Grid>
        <Grid item>
          <Link href="https://www.weconnecthealth.io/contact" variant="body2">
            {"Don't have an account yet?"}
          </Link>
        </Grid>
      </Grid>
    </AuthForm>
  )
}
