import { combineReducers, configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { migrations } from './migrations'
import { reducer as auth } from './slices/auth'
import { reducer as autosave } from './slices/autosave'
import { reducer as messaging } from './slices/messaging'
import { reducer as multiselect } from './slices/multiselect'
import { reducer as peerPortal } from './slices/peerPortal'
import { reducer as testMode } from './slices/testMode'

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth', 'messaging', 'multiselect', 'peerPortal'],
}

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['error', 'loading'],
}

const peerPortalPersistConfig = {
  key: 'peerPortal',
  storage,
  migrate: createMigrate(migrations, { debug: false }),
  version: 1,
}

export const reducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  messaging,
  testMode,
  autosave,
  multiselect,
  peerPortal: persistReducer(peerPortalPersistConfig, peerPortal),
})

const persistedReducer = persistReducer<RootState>(rootPersistConfig, reducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
      },
    }),
  enhancers: [
    Sentry.createReduxEnhancer({
      attachReduxState: false,
      actionTransformer: action => {
        if (action.type === 'auth/setServer2020Token') return null
        if (action.type === 'auth/login/fulfilled')
          return {
            ...action,
            payload: { ...action.payload, sendbirdToken: null, user: null },
          }
        return action
      },
    }),
  ],
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof reducer>
export type AppDispatch = typeof store.dispatch
