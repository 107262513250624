import { useApolloClient } from '@apollo/client'
import {
  AccountCircleOutlined,
  ExitToAppOutlined,
  PersonOutlineOutlined,
  ScienceOutlined,
} from '@mui/icons-material'
import {
  Box,
  Divider,
  Menu,
  Switch,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { selectUserName } from 'src/store/selectors/auth'
import { selectTestMode } from 'src/store/selectors/testMode'
import { toggleTestMode } from 'src/store/slices/testMode'
import { logout } from 'src/store/thunks/auth'
import { useAppDispatch } from 'src/store/useAppDispatch'
import { useAppSelector } from 'src/store/useAppSelector'
import { settingsColor } from 'src/styles/colors'
import { DashboardMode } from 'src/types'

import { AccountMenuItem } from './AccountMenuItem'

interface Props {
  dashboardMode: DashboardMode
}

const useStyles = makeStyles((theme: Theme) => ({
  accountButton: {
    padding: theme.spacing(1.5),
    border: 'none',
    borderRadius: '50px',
  },
  accountIcon: {
    padding: theme.spacing(1),
    color: settingsColor,
  },
}))

export const AccountMenu = ({ dashboardMode }: Props) => {
  const [accountMenuAnchor, setAccountMenuAnchor] =
    useState<HTMLElement | null>(null)
  const classes = useStyles()
  const accountMenuOpen = Boolean(accountMenuAnchor)
  const dispatch = useAppDispatch()
  const userName = useAppSelector(selectUserName)
  const navigate = useNavigate()
  const client = useApolloClient()
  const testMode = useAppSelector(selectTestMode)

  const handleLogout = () => dispatch(logout())

  const handleAccountMenuOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => setAccountMenuAnchor(event.currentTarget)

  const handleAccountMenuClose = () => setAccountMenuAnchor(null)

  const handleToggleTestMode = async (e?: ChangeEvent) => {
    e?.preventDefault()
    dispatch(toggleTestMode())
    await client.refetchQueries({ include: 'active' })
  }

  return (
    <ToggleButtonGroup value={dashboardMode}>
      <ToggleButton
        value={'settings'}
        onClick={handleAccountMenuOpen}
        data-testid="account"
        className={classes.accountButton}>
        <AccountCircleOutlined
          className={classes.accountIcon}
          fontSize="large"
        />
      </ToggleButton>
      <Menu
        anchorEl={accountMenuAnchor}
        id="header-account-menu"
        keepMounted
        open={accountMenuOpen}
        onClose={handleAccountMenuClose}>
        <AccountMenuItem
          onClick={() => navigate('/settings')}
          icon={PersonOutlineOutlined}>
          <Box flexDirection="row" flex={1}>
            <Typography variant="body2">{userName}</Typography>
            <Typography
              color="secondary"
              variant="body1"
              fontSize={12}
              fontWeight={600}>
              Manage Account
            </Typography>
          </Box>
        </AccountMenuItem>
        <Divider style={{ margin: '3px 0' }} />
        <AccountMenuItem icon={ScienceOutlined} onClick={handleToggleTestMode}>
          <Box display="flex" flexDirection="row" flex={1} alignItems="center">
            <Typography variant="body2">Test Mode</Typography>
            <Switch
              size="small"
              checked={testMode}
              onChange={handleToggleTestMode}
            />
          </Box>
        </AccountMenuItem>
        <Divider style={{ margin: '3px 0' }} />
        <AccountMenuItem
          data-testid="sign-out"
          onClick={handleLogout}
          icon={ExitToAppOutlined}
          color="error"
          label="Sign Out"
        />
      </Menu>
    </ToggleButtonGroup>
  )
}
