import { useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { graphql } from 'src/gql'
import {
  MessagingBenefitStatus,
  Order,
  QueryMembersOrderBy,
} from 'src/gql/graphql'
import { BaseSearchParams, useSearchParams } from 'src/hooks'
import { selectIsPeer, selectUserId } from 'src/store/selectors/auth'
import { useAppSelector } from 'src/store/useAppSelector'

const PeerPortalMembersQuery = graphql(`
  query PeerPortalMembers($input: QueryMembersInput!) {
    queryMembers(input: $input) {
      order
      orderBy
      page
      pageSize
      totalCount
      membersV2 {
        id
        name
        createdAt
        totalEngagementDuration
        mesNotes
        languageTag
        peerChangeRequest {
          createdAt
          peer {
            id
            name
          }
        }
        peerMatchSurvey {
          id
        }
        peerSupportQuestionnaire {
          id
        }
        checkins(limit: 1) {
          date
        }
        contactPermissions {
          id
          name
        }
        benefits {
          id
          messagingChannelUrl
          messagingEndsAt
          maxPotentialRewards
          sentMessageCount
          channelLastReadAt
          lastMessageSentAt
          lastMessageSentBy
          activatedAt
          peer {
            id
            name
          }
          contract {
            id
            name
          }
        }
        wellnessGoals {
          id
        }
      }
    }
  }
`)

export const usePeerPortalMembers = () => {
  const { params } = useSearchParams<BaseSearchParams<QueryMembersOrderBy>>()
  const order = params.order ?? Order.Desc
  const orderBy = params.orderBy ?? QueryMembersOrderBy.CreatedAt
  const searchTerm = params.searchTerm
  const isPeer = useAppSelector(selectIsPeer)
  const userId = useAppSelector(selectUserId)
  const rowsPerPage = (params.rowsPerPage && +params.rowsPerPage) || 50
  const page = (params.page && +params.page) || 1
  const messagingBenefitStatus =
    (params.messagingBenefitStatus as MessagingBenefitStatus) ??
    MessagingBenefitStatus.Active

  let peerId: number | null | undefined
  if (params.peerId === '0') peerId = null
  else if (params.peerId) peerId = +params.peerId
  else if (isPeer) peerId = userId

  let contractId: number | null | undefined
  if (params.contractId === '0') contractId = null
  else if (params.contractId) contractId = +params.contractId

  let engaged: boolean | undefined
  if (params.engaged === 'true') engaged = true
  else if (params.engaged === 'false') engaged = false

  const [fetch, { data, loading }] = useLazyQuery(PeerPortalMembersQuery, {
    fetchPolicy: 'cache-and-network',
  })

  const fetchCallback = useCallback(
    (...args: Parameters<typeof fetch>) => fetch(...args),
    [fetch],
  )
  const debouncedFetch = useMemo(
    () => debounce(fetchCallback, 500, { leading: true }),
    [fetchCallback],
  )

  useEffect(() => {
    debouncedFetch({
      variables: {
        input: {
          page,
          pageSize: rowsPerPage,
          query: searchTerm,
          benefits: { contractId, peerId },
          engaged,
          messagingBenefitStatus,
          order: order === Order.Desc ? Order.Desc : Order.Asc,
          orderBy,
        },
      },
    })
  }, [
    page,
    rowsPerPage,
    order,
    orderBy,
    searchTerm,
    peerId,
    contractId,
    engaged,
    messagingBenefitStatus,
    debouncedFetch,
  ])

  return {
    members: data?.queryMembers.membersV2 ?? [],
    loading,
    totalCount: data?.queryMembers.totalCount ?? 0,
    orderBy: data?.queryMembers.orderBy,
    order: data?.queryMembers.order,
    rowsPerPage,
    page,
  }
}
