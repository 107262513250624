import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'

interface ConfirmationDialogProps {
  title: string
  message?: string
  open: boolean
  onClose(value: boolean): void
  confirmText?: string
  cancelText?: string
  disabled?: boolean
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  title,
  message,
  cancelText,
  confirmText,
  disabled,
}) => {
  const handleCancel = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    onClose(false)
  }

  const handleOk = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    onClose(true)
  }

  return (
    <Dialog onClose={handleCancel} open={open}>
      <DialogTitle
        sx={t => ({ padding: t.spacing(2, 2, 1), color: t.palette.grey[800] })}>
        {title}
      </DialogTitle>
      {message && (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions sx={t => ({ padding: t.spacing(0, 2, 2) })}>
        <Button
          autoFocus
          color="primary"
          data-testid="confirmation-cancel"
          onClick={handleCancel}
          variant="outlined">
          {cancelText ?? 'Cancel'}
        </Button>
        <Button
          disabled={disabled}
          color="primary"
          data-testid="confirmation-ok"
          onClick={handleOk}
          variant="contained">
          {confirmText ?? 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
