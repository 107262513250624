import type { ApolloClient } from '@apollo/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { graphql } from 'src/gql'

export interface LoginInput {
  email: string
  password: string
}

const SignInDashboardUser = graphql(`
  mutation SignInDashboardUser($input: SignInDashboardUserInput!) {
    signInDashboardUserV2(input: $input) {
      success
      message
      session {
        jwt
        sendbirdToken {
          token
          expiresAt
        }
      }
      dashboardUser {
        id
        firstName
        role
      }
    }
  }
`)

type LoginProps = {
  input: LoginInput
  client: ApolloClient<object>
}
export const login = createAsyncThunk(
  'auth/login',
  async ({ input, client }: LoginProps) => {
    const { data: mutationData, errors } = await client.mutate({
      mutation: SignInDashboardUser,
      variables: { input },
    })

    if (errors || !mutationData) return Promise.reject()

    const { session, dashboardUser, success, message } =
      mutationData.signInDashboardUserV2

    if (!success)
      return { error: message ?? 'Email or password not recognized' }
    const sendbirdToken = session?.sendbirdToken ?? { token: '', expiresAt: 0 }

    return {
      user: dashboardUser,
      sendbirdToken,
      error: null,
      server2020Token: session?.jwt,
    }
  },
)

export const logout = createAsyncThunk('auth/logout', async () => {
  storage.removeItem('persist:root')
  storage.removeItem('persist:auth')
})
