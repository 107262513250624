import { createStyles, makeStyles } from '@mui/styles'
import React from 'react'

import iconColored from '../../assets/images/iconColored.png'
import logoColored from '../../assets/images/logoColored.png'

const useStyles = makeStyles(theme =>
  createStyles({
    logoDesktop: {
      height: 35,
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    logoMobile: {
      height: 35,
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
)
export const ResponsiveLogo: React.FC = () => {
  const classes = useStyles()
  return (
    <>
      <img
        className={classes.logoMobile}
        src={iconColored}
        alt="WEconnect Logo"
      />
      <img
        className={classes.logoDesktop}
        src={logoColored}
        alt="WEconnect Logo"
      />
    </>
  )
}
