import { useApolloClient } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid, Link, TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { PasswordInput } from 'src/components/Inputs/PasswordInput'
import { login, LoginInput } from 'src/store/thunks/auth'
import { useAppDispatch } from 'src/store/useAppDispatch'
import * as Yup from 'yup'

import { AuthForm } from './AuthForm'

const schema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string().required('Required'),
})

export const Login = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const client = useApolloClient()
  const {
    control,
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<LoginInput>({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(schema),
  })

  return (
    <AuthForm
      onSubmit={handleSubmit(input => dispatch(login({ input, client })))}>
      <TextField
        {...register('email')}
        autoComplete="email"
        autoFocus
        fullWidth
        label="Email Address"
        variant="outlined"
        error={!!errors.email}
        helperText={errors.email?.message}
        required
      />
      <PasswordInput control={control} name="password" />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        sx={{ margin: t => t.spacing(2, 0) }}
        disabled={isSubmitting}>
        {isSubmitting ? 'Signing in...' : 'Sign in'}
      </Button>
      <Grid container>
        <Grid item xs>
          <Link
            href="#"
            onClick={() => navigate(`/forgot-password`)}
            variant="body2"
            data-testid="forgot-password">
            Forgot password?
          </Link>
        </Grid>
        <Grid item>
          <Link href="https://www.weconnecthealth.io/contact" variant="body2">
            {"Don't have an account yet?"}
          </Link>
        </Grid>
      </Grid>
    </AuthForm>
  )
}
