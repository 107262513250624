import { Box, CircularProgress, CircularProgressProps } from '@mui/material'
import React from 'react'

export const Spinner: React.FC<CircularProgressProps> = (
  props: CircularProgressProps,
) => (
  <Box
    display="flex"
    flex={1}
    justifyContent="center"
    alignItems="center"
    margin={t => t.spacing(2)}>
    <CircularProgress {...props} />
  </Box>
)
