import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'

interface PasswordInputProps<T extends FieldValues>
  extends StandardTextFieldProps {
  control: Control<T, unknown>
  name: Path<T>
  label?: string
  visibilityControl?: Function
  visibility?: boolean
}

export const PasswordInput = <T extends FieldValues>({
  control,
  label = 'Password',
  name,
  visibility,
  visibilityControl,
  ...props
}: PasswordInputProps<T>) => {
  const [visible, setVisible] = useState(false)
  const { field } = useController({ control, name })

  const toggleVisibility = (): void => {
    visibilityControl ? visibilityControl() : setVisible(!visible)
  }

  const isVisible = (): boolean => {
    return visibility || visible
  }

  return (
    <TextField
      {...field}
      {...props}
      required
      autoComplete="current-password"
      fullWidth
      label={label}
      margin="normal"
      type={isVisible() ? 'text' : 'password'}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={toggleVisibility} size="large">
              {isVisible() ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
