import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EngagementFormValues } from 'src/components/Engagement/types'

export type SerializedEngagementForm = Omit<
  EngagementFormValues,
  'startedAt'
> & {
  startedAt: string
}

interface AutosaveState {
  engagements: Record<string, Partial<SerializedEngagementForm> | undefined>
}

const initialState: AutosaveState = {
  engagements: {},
}

const autosave = createSlice({
  name: 'autosave',
  initialState,
  reducers: {
    saveEngagement: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: string
        engagement: Partial<SerializedEngagementForm>
      }>,
    ) => {
      state.engagements[payload.key] = payload.engagement
    },

    clearEngagement: (state, { payload }: PayloadAction<string>) => {
      state.engagements[payload] = undefined
    },
  },
})

export const { saveEngagement, clearEngagement } = autosave.actions
export const { reducer } = autosave
