import { LensBlur } from '@mui/icons-material'
import PetsIcon from '@mui/icons-material/Pets'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useTheme,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { Link, useLocation } from 'react-router-dom'
import { selectIsAdmin } from 'src/store/selectors/auth'
import { useAppSelector } from 'src/store/useAppSelector'

import { adminColor, peerPortalColor, tigerColor } from '../../styles/colors'
import { DashboardMode } from '../../types'
import { TotalUnreadMessageCountBadge } from '../TotalUnreadMessageCountBadge'

const useStyles = makeStyles((theme: Theme) => ({
  toggleButton: {
    padding: 0,
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': { borderRadius: theme.shape.borderRadius },
    '&:first-child': { borderRadius: theme.shape.borderRadius },
  },
  buttonLink: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
}))

interface Props {
  dashboardMode: DashboardMode
}

export const DashboardSelector = ({ dashboardMode }: Props) => {
  const classes = useStyles()
  const isAdmin = useAppSelector(selectIsAdmin)
  const theme = useTheme()
  const { pathname } = useLocation()

  return (
    <ToggleButtonGroup
      value={dashboardMode}
      exclusive
      size="small"
      sx={{
        opacity: 0.6,
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
          marginRight: theme.spacing(4),
        },
      }}>
      {isAdmin && [
        <ToggleButton
          key="admin"
          className={classes.toggleButton}
          value="admin">
          <Tooltip title="Admin" arrow>
            <Link
              className={classes.buttonLink}
              data-testid="admin-dashboard-link"
              to="/admin">
              <SupervisorAccountOutlinedIcon
                fontSize="small"
                style={{ color: adminColor }}
              />
            </Link>
          </Tooltip>
        </ToggleButton>,
        <ToggleButton
          key="tiger"
          className={classes.toggleButton}
          value="tiger">
          <Tooltip title="Tiger" arrow>
            <Link
              className={classes.buttonLink}
              data-testid="tiger-dashboard-link"
              to="/tiger">
              <PetsIcon fontSize="small" style={{ color: tigerColor }} />
            </Link>
          </Tooltip>
        </ToggleButton>,
      ]}
      <ToggleButton className={classes.toggleButton} value="peerPortal">
        <Tooltip title="Peer Portal" arrow>
          <Link
            className={classes.buttonLink}
            data-testid="peer-portal-dashboard-link"
            to={'/peer-portal/members'}>
            {pathname.includes('peer-portal') ? (
              <LensBlur fontSize="small" style={{ color: peerPortalColor }} />
            ) : (
              <TotalUnreadMessageCountBadge>
                <LensBlur fontSize="small" style={{ color: peerPortalColor }} />
              </TotalUnreadMessageCountBadge>
            )}
          </Link>
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
