import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { logError } from 'src/common/logger'
import { graphql } from 'src/gql'

const RequestPasswordResetMutation = graphql(`
  mutation RequestPasswordReset($email: String!) {
    requestDashboardPasswordReset(email: $email) {
      success
      message
    }
  }
`)

const ResetPasswordMutation = graphql(`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPasswordForDashboardUser(input: $input) {
      success
      message
      session {
        ... on Session {
          jwt
        }
      }
    }
  }
`)

export const useResetPassword = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const [request] = useMutation(RequestPasswordResetMutation, {
    onCompleted: ({ requestDashboardPasswordReset: { success, message } }) => {
      enqueueSnackbar(message, { variant: success ? 'success' : 'error' })
      navigate('/login')
    },
    onError: error => {
      logError('Error sending reset password link', error)
      enqueueSnackbar('Could not send you a reset password link', {
        variant: 'error',
      })
    },
  })

  const [reset] = useMutation(ResetPasswordMutation, {
    onCompleted: ({ resetPasswordForDashboardUser: { success, message } }) => {
      enqueueSnackbar(message, { variant: success ? 'success' : 'error' })
      navigate('/login')
    },
    onError: error => {
      logError('Error resetting password', error)
      enqueueSnackbar('Could not reset password', { variant: 'error' })
    },
  })

  return {
    requestResetPassword: async ({ email }: { email: string }) =>
      request({ variables: { email } }),
    resetPassword: async ({ password }: { password: string }) =>
      reset({ variables: { input: { password } } }),
  }
}
