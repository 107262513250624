import SendIcon from '@mui/icons-material/Send'
import { Box, IconButton, TextField } from '@mui/material'
import {
  sendbirdSelectors,
  useSendbirdStateContext,
} from '@sendbird/uikit-react'
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context'
import { useState } from 'react'

export const MessageInput = () => {
  const [messageText, setMessageText] = useState('')
  const sbContext = useSendbirdStateContext()
  const sendMessage = sendbirdSelectors.getSendUserMessage(sbContext)
  const { currentChannel: selectedChannel } = useGroupChannelContext()
  const hasValidStagedMessage = !!messageText.trim()

  if (!selectedChannel) return null

  const onClick = async () => {
    sendMessage(selectedChannel, { message: messageText }).onSucceeded(
      async () => {
        setMessageText('')
        await sendStopTyping()
      },
    )
  }

  const sendStopTyping = async () => selectedChannel.endTyping?.()

  return (
    <Box
      flex="0 0 auto"
      width="100%"
      margin={t => t.spacing(1, 0)}
      paddingX={t => t.spacing(2)}>
      <TextField
        value={messageText}
        multiline
        fullWidth
        size="small"
        variant="outlined"
        placeholder="Type a message"
        InputProps={{
          sx: { borderRadius: 36, fontSize: '1rem', background: 'white' },
          fullWidth: true,
          endAdornment: (
            <IconButton
              size="small"
              disabled={!selectedChannel || !hasValidStagedMessage}
              onClick={onClick}>
              <SendIcon fontSize="small" />
            </IconButton>
          ),
        }}
        onChange={async event => {
          setMessageText(event.currentTarget.value)
          event.currentTarget.value
            ? selectedChannel.startTyping()
            : sendStopTyping()
        }}
        onKeyDown={e => {
          if (e.metaKey && e.key === 'Enter') {
            e.preventDefault()
            if (!hasValidStagedMessage) return

            onClick()
          }
        }}
      />
    </Box>
  )
}
