import { Box } from '@mui/material'
import { ChildrenProp, Suspense, useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ConversationPopover } from 'src/components/ConversationPopover'
import { selectIsLoggedIn } from 'src/store/selectors/auth'
import { useAppSelector } from 'src/store/useAppSelector'

import { FallbackContainer } from './FallbackContainer'

interface Props {
  children?: ChildrenProp
}

export const BaseContainer = ({ children }: Props) => {
  const routeRef = useRef<HTMLElement>(null)
  const { pathname } = useLocation()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  return (
    <Box>
      <Box ref={routeRef} height="100vh" display="flex" flexDirection="column">
        <Suspense fallback={<FallbackContainer />}>
          {children ?? <Outlet />}
        </Suspense>
      </Box>
      <div style={{ display: 'none' }}>{import.meta.env.VITE_RELEASE_ID}</div>
      {isLoggedIn &&
        pathname.includes('peer-portal') &&
        !pathname.includes('messages') && (
          <ConversationPopover anchorEl={routeRef} />
        )}
    </Box>
  )
}
