import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, Container, Theme, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import logoColored from 'src/assets/images/logoColored.png'
import spaceBackground from 'src/assets/images/spaceBackground.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: 'center',
      backgroundImage: `url('${spaceBackground}')`,
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      paddingTop: theme.spacing(12),
      height: '100vh',
    },
    title: {
      color: theme.palette.primary.light,
      marginBottom: theme.spacing(2),
      fontWeight: 800,
    },
    subtitle: {
      color: theme.palette.primary.dark,
      marginBottom: theme.spacing(3),
    },
    featuredImageContainer: {
      position: 'absolute',
      bottom: theme.spacing(4),
      left: 'calc(50% - 120px)',
    },
    featuredImage: { maxWidth: 240 },
  }),
)

export const NotFound: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <Container component="main" className={classes.container}>
      <Typography variant="h1" className={classes.title}>
        Lost in space?
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        The page you requested can’t be found
        <br />
        (or you don’t have access to it)
      </Typography>
      <Button
        color="primary"
        variant="contained"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/')}>
        Back to the dashboard
      </Button>
      <Box className={classes.featuredImageContainer}>
        <img
          className={classes.featuredImage}
          src={logoColored}
          alt="WEconnect logo"
        />
      </Box>
    </Container>
  )
}
