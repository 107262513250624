import { Skeleton, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { Header } from 'src/components/Header'
import { selectIsLoggedIn } from 'src/store/selectors/auth'
import { useAppSelector } from 'src/store/useAppSelector'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centeredContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    },
    authSkeleton: {
      width: 400,
      marginTop: 20,
      marginBottom: theme.spacing(12),
      alignSelf: 'center',
    },
    authnField: {
      marginBottom: 20,
    },
  }),
)

export const FallbackContainer = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const classes = useStyles()

  return (
    <>
      {isLoggedIn ? (
        <Header dashboardMode="loading" />
      ) : (
        <div className={classes.centeredContent}>
          <div className={classes.authSkeleton}>
            {[...Array(3)].map((_x, i) => (
              <Skeleton
                key={i}
                variant="rectangular"
                className={classes.authnField}
                animation="wave"
                height={60}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
}
