import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../'

const selectMessaging = (state: RootState) => state.messaging

export const selectSelectedChannelUrl = createSelector(
  selectMessaging,
  messaging => messaging.selectedChannelUrl,
)
