import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  sectionHeader: {
    marginTop: theme.spacing(2),
  },
}))

export const ListHeading: React.FC<{ children?: React.ReactNode }> = props => {
  const classes = useStyles()
  return (
    <Typography
      className={classes.sectionHeader}
      variant="subtitle2"
      color="textPrimary"
      {...props}
    />
  )
}
