import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'

interface Props {
  children: React.ReactNode
  divider?: boolean
  label: React.ReactNode
}

export const LabeledListItem: React.FC<Props> = ({
  label,
  children,
  ...rest
}) => (
  <ListItem style={{ paddingLeft: 0, paddingRight: 0 }} divider {...rest}>
    <ListItemText secondary={label} />
    {children}
  </ListItem>
)
