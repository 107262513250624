import { ApolloLink, Context } from '@apollo/client'
import { ContextSetter, setContext } from '@apollo/client/link/context'
import { store } from 'src/store'
import { getSlice } from 'src/store/getSlice'
import { selectServer2020Token } from 'src/store/selectors/auth'
import { setServer2020Token } from 'src/store/slices/auth'

export const contextSetter: ContextSetter = (_, context: Context) => {
  const server2020Token = getSlice(selectServer2020Token)

  return {
    ...context,
    headers: {
      ...context.headers,
      authorization: server2020Token ? `Bearer ${server2020Token}` : '',
    },
  }
}

export const authLink = setContext(contextSetter)

export const refreshTokenLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const headers = operation.getContext()?.response?.headers
    const refreshToken = headers?.get('x-refresh-token')
    if (refreshToken) store.dispatch(setServer2020Token(refreshToken))

    return response
  })
})
