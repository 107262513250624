import { Box, Container, Link, Typography, useTheme } from '@mui/material'
import { BaseSyntheticEvent } from 'react'
import logoColored from 'src/assets/images/logoColored.png'

interface Props {
  children: React.ReactNode
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>
}

export const AuthForm: React.FC<Props> = ({ onSubmit, children }) => {
  const theme = useTheme()

  return (
    <Box display="flex" alignItems="center" height="100vh">
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: theme.spacing(12),
        }}>
        <Box mb={t => t.spacing(3)}>
          <img width="300" src={logoColored} alt="WEconnect Logo" />
        </Box>
        <form onSubmit={onSubmit}>{children}</form>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          mt={t => t.spacing(6)}>
          {'Copyright © '}
          <Link color="inherit" href="https://www.weconnecthealth.io/">
            WEconnect
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Container>
    </Box>
  )
}
