import React, { Profiler } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { logProfiler } from 'src/common/logProfiler'
import { Header } from 'src/components/Header'
import { PageContainer } from 'src/screens/PageContainer'
import { selectIsAdmin, selectIsLoggedIn } from 'src/store/selectors/auth'
import { useAppSelector } from 'src/store/useAppSelector'
import { BaseLink, DashboardMode } from 'src/types'

interface Props {
  headerMode: DashboardMode
  navLinks?: BaseLink[]
}

export const AdminLayout: React.FC<Props> = ({ headerMode, navLinks }) => {
  const isAdmin = useAppSelector(selectIsAdmin)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const location = useLocation()

  if (!isLoggedIn)
    return <Navigate state={{ from: location }} to="/login" replace />
  else if (!isAdmin) return <Navigate to="/" replace />

  return (
    <Profiler id="Admin" onRender={logProfiler}>
      <Header dashboardMode={headerMode} navLinks={navLinks} />
      <PageContainer>
        <Outlet />
      </PageContainer>
    </Profiler>
  )
}
