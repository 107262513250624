import { Box, Paper, Popper } from '@mui/material'
import { GroupChannel as IGroupChannel } from '@sendbird/chat/groupChannel'
import {
  sendbirdSelectors,
  useSendbirdStateContext,
} from '@sendbird/uikit-react'
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel'
import { RefObject, useEffect, useState } from 'react'
import { Message } from 'src/components/Message'
import { useAddToChannel } from 'src/screens/PeerPortal/Members/useAddToChannel'
import { selectSelectedChannelUrl } from 'src/store/selectors/messaging'
import { useAppSelector } from 'src/store/useAppSelector'

import { ConversationHeader } from '../ConversationHeader'
import { MessageInput } from '../MessageInput'
import { Spinner } from '../Spinner'

interface Props {
  anchorEl: RefObject<HTMLElement | null>
}

export const ConversationPopover = ({ anchorEl }: Props) => {
  const [channel, setChannel] = useState<IGroupChannel>()
  const channelUrl = useAppSelector(selectSelectedChannelUrl)
  const sbContext = useSendbirdStateContext()
  const getChannel = sendbirdSelectors.getGetGroupChannel(sbContext)

  const { addToChannel } = useAddToChannel()

  useEffect(() => {
    setChannel(undefined)
    let retries = 0

    const makeRequest = async () => {
      if (!channelUrl || retries > 2) return
      try {
        const channel = await getChannel(channelUrl)
        setChannel(channel)
      } catch (_) {
        if (retries === 0) await addToChannel(channelUrl)
        retries++
        await makeRequest()
      }
    }

    makeRequest()
  }, [channelUrl, addToChannel, getChannel])

  if (!channelUrl || !channel) return null

  return (
    <Popper
      style={{ zIndex: 3 }}
      open={!!channelUrl}
      anchorEl={anchorEl.current}
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'viewport',
            padding: 8,
          },
        },
      ]}
      placement="bottom-end">
      <Paper
        sx={t => ({
          width: 700,
          [t.breakpoints.down('md')]: {
            width: '99vw',
          },
        })}
        elevation={8}>
        <Box sx={{ height: 550, display: 'flex' }}>
          <GroupChannel
            channelUrl={channel.url}
            isReactionEnabled={false}
            replyType="NONE"
            messageListQueryParams={{
              prevResultLimit: 30,
              nextResultLimit: 30,
            }}
            disableMarkAsRead={false}
            renderMessageContent={Message}
            renderChannelHeader={() => <ConversationHeader showClose />}
            renderMessageInput={() => <MessageInput />}
            renderPlaceholderLoader={() => <Spinner />}
          />
        </Box>
      </Paper>
    </Popper>
  )
}
