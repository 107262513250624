import { Member } from '@sendbird/chat/groupChannel'
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context'
import { useEffect, useState } from 'react'

export const useAppMember = () => {
  const { currentChannel: channel } = useGroupChannelContext()
  const [appMember, setAppMember] = useState<Member>()

  useEffect(() => {
    const effect = async () => {
      if (!channel) return
      setAppMember(
        channel.members.find(({ userId }) => userId.includes('app_')),
      )
    }

    effect()
  }, [channel])

  return { appMember }
}
