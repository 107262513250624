import { createSelector } from '@reduxjs/toolkit'
import { Role } from 'src/gql/graphql'
import { RootState } from 'src/store'

const selectAuthSlice = (state: RootState) => state.auth

export const selectLoading = createSelector(
  selectAuthSlice,
  auth => auth.loading,
)

export const selectError = createSelector(selectAuthSlice, auth => auth.error)

export const selectSendbirdToken = createSelector(
  selectAuthSlice,
  auth => auth.sendbirdToken,
)

export const selectServer2020Token = createSelector(
  selectAuthSlice,
  auth => auth.server2020Token,
)

export const selectUser = createSelector(selectAuthSlice, auth => auth.user)

export const selectUserId = createSelector(selectUser, user => user?.id)

export const selectUserName = createSelector(
  selectUser,
  user => user?.firstName,
)

export const selectIsAdmin = createSelector(
  selectUser,
  user => user?.role === Role.Admin,
)

export const selectIsPeer = createSelector(
  selectUser,
  user => user?.role === Role.Peer,
)

export const selectIsPeerSupervisor = createSelector(
  selectUser,
  user => user?.role === Role.PeerSupervisor,
)

export const selectIsMES = createSelector(
  selectUser,
  user => user?.role === Role.MemberEngagementSpecialist,
)

export const selectCanManagePeers = createSelector(
  [selectIsAdmin, selectIsPeerSupervisor, selectIsMES],
  (isAdmin, isPeerSupervisor, isMES) => isAdmin || isPeerSupervisor || isMES,
)

export const selectIsAssignable = createSelector(
  [selectIsPeer, selectIsPeerSupervisor, selectIsMES],
  (isPeer, isPeerSupervisor, isMES) => isPeer || isPeerSupervisor || isMES,
)

export const selectIsLoggedIn = createSelector(
  [selectUser, selectLoading, selectServer2020Token],
  (user, loading, server2020Token) => !!server2020Token && !!user && !loading,
)
