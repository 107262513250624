import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { logError } from 'src/common/logger'
import { graphql } from 'src/gql'
import { ChangePasswordInput } from 'src/gql/graphql'

const ChangePasswordMutation = graphql(`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changeDashboardUserPassword(input: $input) {
      success
      message
    }
  }
`)

export const useUpdatePassword = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [mutate, { loading }] = useMutation(ChangePasswordMutation, {
    onCompleted: ({ changeDashboardUserPassword: { success, message } }) => {
      enqueueSnackbar(message, { variant: success ? 'success' : 'error' })
    },
    onError: error => {
      logError('Error updating password', error)
      enqueueSnackbar('Could not update your password.', { variant: 'error' })
    },
  })

  return {
    updatePassword: async (input: ChangePasswordInput) =>
      await mutate({ variables: { input } }),
    loading,
  }
}
