import { Dialog, DialogContent } from '@mui/material'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { MemberShow } from './MemberShow'

export const MembersShowModal: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <Dialog
      open={true}
      onClose={() =>
        navigate({ pathname: '/tiger/members', search: location.search })
      }>
      <DialogContent>
        <MemberShow />
      </DialogContent>
    </Dialog>
  )
}
