import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Create'
import { Box, FormControl, IconButton, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import { ContractFragment } from 'src/gql/graphql'
import { useContracts } from 'src/hooks/useContracts'

import { useUpdateMember } from './useUpdateMember'

interface Props {
  memberId: number
}

export const SelectContract: React.FC<Props> = ({ memberId }) => {
  const { upgradeByAdmin } = useUpdateMember()
  const [isEditing, setEditing] = useState<boolean>(false)
  const { contracts } = useContracts()
  const [contractId, setContractId] = useState<number | null>(null)

  const onConfirm = async () => {
    if (contractId) {
      const { data } = await upgradeByAdmin({ contractId, memberId })
      if (!data?.upgradeByAdmin.success) resetValue()
    } else resetValue()
  }

  const resetValue = () => {
    setContractId(null)
    setEditing(false)
  }

  return (
    <Box>
      <FormControl sx={{ width: 200 }}>
        <Select
          aria-label="Contracts"
          value={contractId ?? ''}
          variant="outlined"
          onChange={event => setContractId(+event.target.value || null)}
          size="small"
          disabled={!isEditing}
          displayEmpty>
          <MenuItem value={''}>
            <em>-- None --</em>
          </MenuItem>
          {(contracts as ContractFragment[]).map(contract => (
            <MenuItem key={contract.id} value={contract.id.toString()}>
              {contract.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isEditing ? (
        <>
          <IconButton title="Confirm" onClick={onConfirm} size="small">
            <CheckIcon htmlColor={'green'} />
          </IconButton>
          <IconButton title="Reset" onClick={resetValue} size="small">
            <CloseIcon htmlColor="red" />
          </IconButton>
        </>
      ) : (
        <IconButton title="Edit" onClick={() => setEditing(true)} size="small">
          <EditIcon />
        </IconButton>
      )}
    </Box>
  )
}
