import { Close } from '@mui/icons-material'
import { Box, Chip, IconButton, Typography, useTheme } from '@mui/material'
import { capitalize } from 'lodash'
import { Link } from 'react-router-dom'
import { AccountStatus } from 'src/gql/graphql'
import { useAppMember } from 'src/screens/PeerPortal/Messages/useAppMember'
import { clearSelectedChannel } from 'src/store/slices/messaging'
import { useAppDispatch } from 'src/store/useAppDispatch'

import { Spinner } from '../Spinner'
import { LeaveConversationButton } from './LeaveConversationButton'
import { useMemberConversationInfo } from './useMemberConversationInfo'

interface Props {
  showClose?: boolean
}

export const ConversationHeader = ({ showClose }: Props) => {
  const theme = useTheme()
  const { appMember } = useAppMember()
  const memberId = appMember?.userId.replace('app_', '')
  const { createdAt, subscription, benefits, messagingEnabled, accountStatus } =
    useMemberConversationInfo(memberId)
  const joinDate = createdAt ? `Joined ${createdAt.toFormat('D')}` : ''
  const benefitSource = subscription
    ? `Subscription - ${capitalize(subscription?.price?.name ?? '')}`
    : benefits?.contract?.name ?? 'N/A'
  const benefitExpired =
    !messagingEnabled || accountStatus === AccountStatus.Deactivated
  const dispatch = useAppDispatch()
  if (!memberId || !benefits) return <Spinner />
  return (
    <Box
      flex="0 0 auto"
      mt={theme.spacing(1)}
      py={theme.spacing(1)}
      px={theme.spacing(2)}
      borderBottom={`1px solid ${theme.palette.grey[200]}`}
      mb={theme.spacing(2)}>
      <Box display="flex">
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="row"
          alignItems="center">
          <Link to={`/peer-portal/members/${memberId}`}>
            <Typography variant="h6">{appMember?.nickname}</Typography>
          </Link>
          {benefitExpired && (
            <Chip
              sx={{ marginLeft: theme.spacing(1) }}
              size="small"
              color="error"
              label="Benefit Expired"
            />
          )}
        </Box>
        {showClose && (
          <IconButton
            size="small"
            onClick={() => dispatch(clearSelectedChannel())}>
            <Close />
          </IconButton>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <Box display="flex">
          <Box>
            <Typography
              lineHeight={1}
              variant="caption"
              pr={theme.spacing(2)}
              borderRight="1px solid">
              ID: {memberId}
            </Typography>
          </Box>
          <Box>
            <Typography
              lineHeight={1}
              variant="caption"
              px={theme.spacing(2)}
              borderRight="1px solid">
              {benefitSource}
            </Typography>
          </Box>
          <Box>
            <Typography lineHeight={1} variant="caption" px={theme.spacing(2)}>
              {joinDate}
            </Typography>
          </Box>
        </Box>
        <Box ml="auto">
          <LeaveConversationButton />
        </Box>
      </Box>
    </Box>
  )
}
