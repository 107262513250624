import { MoreVert } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { BaseLink } from '../../types'
import { TotalUnreadMessageCountBadge } from '../TotalUnreadMessageCountBadge'

interface Props {
  navLinks?: BaseLink[]
  color: string
}

export const NavMainMenu: React.FC<Props> = ({ navLinks, color }) => {
  const { breakpoints, spacing } = useTheme()
  const [mobileAnchorEl, setMobileAnchorEl] = useState<null | HTMLElement>(null)

  const handleMobileMenuClose = () => setMobileAnchorEl(null)

  const handleMobileMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) =>
    setMobileAnchorEl(e.currentTarget)

  const mobileMenuId = 'header-main-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ flexDirection: 'column', display: 'flex' }}
      id={mobileMenuId}
      keepMounted
      open={!!mobileAnchorEl}
      onClose={handleMobileMenuClose}>
      {navLinks &&
        navLinks.map((navLink: BaseLink, i: number) => (
          <MenuItem key={i} onClick={handleMobileMenuClose}>
            <NavLink to={navLink.link}>
              <Typography variant="body2" style={{ color: color }}>
                {navLink.label}
              </Typography>
            </NavLink>
          </MenuItem>
        ))}
    </Menu>
  )
  return (
    <Box flexGrow={1}>
      <Box
        sx={{ display: 'none', [breakpoints.up('md')]: { display: 'flex' } }}>
        {navLinks &&
          navLinks.map(navLink => (
            <NavLink
              style={({ isActive }) => ({
                marginRight: spacing(2),
                textDecoration: 'none',
                borderBottom: isActive ? `3px solid ${color}` : undefined,
              })}
              to={navLink.link}
              key={navLink.label}>
              <Button
                sx={{
                  color: color,
                  '&:hover': { backgroundColor: 'transparent' },
                }}>
                {navLink.label === 'Messages' ? (
                  <TotalUnreadMessageCountBadge>
                    {navLink.label}
                  </TotalUnreadMessageCountBadge>
                ) : (
                  navLink.label
                )}
              </Button>
            </NavLink>
          ))}
      </Box>

      <Box
        ml="auto"
        display="flex"
        justifyContent="flex-end"
        sx={{ [breakpoints.up('md')]: { display: 'none', marginLeft: 0 } }}>
        <IconButton onClick={handleMobileMenuOpen} color="inherit" size="large">
          <MoreVert />
        </IconButton>
      </Box>
      {renderMobileMenu}
    </Box>
  )
}
