import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import TimerIcon from '@mui/icons-material/Timer'
import { Chip, ChipProps } from '@mui/material'
import { green, orange, red } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { AccountStatus } from 'src/gql/graphql'

const useStyles = makeStyles(theme => ({
  activeChip: {
    marginLeft: theme.spacing(2),
    backgroundColor: green[50],
    color: theme.palette.success.dark,
    textTransform: 'capitalize',
  },
  deactivatedChip: {
    marginLeft: theme.spacing(2),
    backgroundColor: red[50],
    color: theme.palette.error.dark,
    textTransform: 'capitalize',
  },
  gracePeriodChip: {
    marginLeft: theme.spacing(2),
    backgroundColor: orange[50],
    color: theme.palette.warning.dark,
    textTransform: 'capitalize',
  },
  activeIcon: {
    color: theme.palette.success.dark,
    marginLeft: 8,
  },
  deactivatedIcon: {
    color: theme.palette.error.dark,
    marginLeft: 8,
  },
  gracePeriodIcon: {
    color: theme.palette.warning.dark,
    marginLeft: 8,
  },
}))

interface Props extends ChipProps {
  accountStatus?: AccountStatus
}

export const AccountStatusChip: React.FC<Props> = ({
  accountStatus,
  ...rest
}) => {
  const classes = useStyles()

  switch (accountStatus) {
    case AccountStatus.Active:
      return (
        <Chip
          className={classes.activeChip}
          icon={<DoneIcon fontSize="small" className={classes.activeIcon} />}
          label="Active"
          {...rest}
        />
      )
    case AccountStatus.GracePeriod:
      return (
        <Chip
          className={classes.gracePeriodChip}
          icon={
            <TimerIcon fontSize="small" className={classes.gracePeriodIcon} />
          }
          label="Grace Period"
          {...rest}
        />
      )
    case AccountStatus.Deactivated:
      return (
        <Chip
          className={classes.deactivatedChip}
          icon={
            <CloseIcon fontSize="small" className={classes.deactivatedIcon} />
          }
          label="Deactivated"
          {...rest}
        />
      )
    default:
      return (
        <Chip
          className={classes.deactivatedChip}
          icon={
            <CloseIcon fontSize="small" className={classes.deactivatedIcon} />
          }
          label="Deleted"
          {...rest}
        />
      )
  }
}
