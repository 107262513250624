import { useMutation, useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { graphql } from 'src/gql'
import { ContractFragment, ContractInput, ContractType } from 'src/gql/graphql'

export interface ContractValues {
  name: string
  durationOfMessaging: number | null
  durationOfRewards: number | null
  startingBalance: number
  planYearStartsAt: DateTime | null
  planYearEarningsLimit: number | null
  requiresReferral: boolean
  endsAt: DateTime | null
  rewardsPlanId: string
  type: ContractType | null
  eligibleMemberCount: number | null
  enrollmentStartsAt: DateTime | null
  enrollmentEndsAt: DateTime | null
  notes: string
}

interface ContractUpsertInput extends ContractInput {
  id?: number
}

const Query = graphql(`
  query Contracts {
    contracts {
      ...Contract
    }
  }
`)

const Create = graphql(`
  mutation CreateContract($input: ContractInput!) {
    createContractV2(input: $input) {
      success
      message
      contract {
        ... on Contract {
          ...Contract
        }
        ... on ValidationErrors {
          validationErrors {
            field
            errors
          }
        }
      }
    }
  }
`)

const Update = graphql(`
  mutation UpdateContract($id: Int!, $input: ContractInput!) {
    updateContract(id: $id, input: $input) {
      success
      message
      contract {
        ... on Contract {
          ...Contract
        }
        ... on ValidationErrors {
          validationErrors {
            field
            errors
          }
        }
      }
    }
  }
`)

const UpdateNotes = graphql(`
  mutation UpdateContractNotes($id: Int!, $notes: String!) {
    updateContractNotes(id: $id, notes: $notes) {
      success
      message
      contract {
        ... on Contract {
          ...Contract
        }
      }
    }
  }
`)

export const useContracts = () => {
  const { data } = useQuery(Query)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [create] = useMutation(Create, {
    onCompleted: ({ createContractV2: { message, success, contract } }) => {
      enqueueSnackbar(message, { variant: success ? 'success' : 'error' })
      if (success)
        navigate(`/admin/contracts/${(contract as ContractFragment)?.id}`)
    },
    onError: () => {
      enqueueSnackbar('Oops. Something went wrong.', { variant: 'error' })
    },
  })
  const [update] = useMutation(Update, {
    onCompleted: ({ updateContract: { message, success } }) => {
      enqueueSnackbar(message, { variant: success ? 'success' : 'error' })
    },
    onError: () => {
      enqueueSnackbar('Oops. Something went wrong.', { variant: 'error' })
    },
  })
  const [updateNotes] = useMutation(UpdateNotes, {
    onCompleted: ({ updateContractNotes: { message, success } }) => {
      enqueueSnackbar(message, { variant: success ? 'success' : 'error' })
    },
    onError: () => {
      enqueueSnackbar('Oops. Something went wrong.', { variant: 'error' })
    },
  })

  const upsert = async ({ id, ...input }: ContractUpsertInput) => {
    const { errors, data } = await (id
      ? update({ variables: { id, input } })
      : create({ variables: { input } }))
    if (errors || !data) return { success: false }
    const { success, contract } =
      'updateContract' in data ? data.updateContract : data.createContractV2
    const validationErrors =
      contract?.__typename === 'ValidationErrors'
        ? contract.validationErrors
        : null
    return { success, validationErrors }
  }

  return {
    contracts: data?.contracts ?? [],
    create: async (input: ContractInput) => create({ variables: { input } }),
    update: async (id: number, input: ContractInput) =>
      update({ variables: { id, input } }),
    upsert,
    updateNotes: async (id: number, notes: string) =>
      updateNotes({ variables: { id, notes } }),
  }
}
