import {
  Link,
  MenuItem,
  SvgIconTypeMap,
  Typography,
  useTheme,
} from '@mui/material'
import { OverridableComponent } from '@mui/types'

interface Props {
  label?: string
  href?: string
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  color?: 'error' | 'inherit'
  onClick?: () => void
  children?: React.ReactNode
}

export const AccountMenuItem: React.FC<Props> = ({
  label,
  href,
  icon: Icon,
  onClick,
  color = 'inherit',
  children,
}) => {
  const theme = useTheme()

  return (
    <MenuItem onClick={onClick}>
      <Link
        href={href}
        color={color}
        underline="none"
        display="flex"
        alignItems="center"
        sx={{ pointer: 'cursor' }}>
        <Icon
          sx={{
            marginRight: theme.spacing(1),
            marginLeft: -theme.spacing(0.5),
            alignSelf: 'baseline',
            marginTop: theme.spacing(0.25),
            fontSize: 18,
          }}
        />
        {label ? <Typography variant="body2">{label}</Typography> : children}
      </Link>
    </MenuItem>
  )
}
