export const themeColorSet = {
  '--sendbird-light-primary-500': '#165258',
  '--sendbird-light-primary-400': '#267c84',
  '--sendbird-light-primary-300': '#37a8b3',
  '--sendbird-light-primary-200': '#91cbd1',
  '--sendbird-light-primary-100': '#b7dce0',
  // hide read and delivery receipts
  '--sendbird-light-secondary-300': '#FFFFFF',
  '--sendbird-light-onlight-03': '#FFFFFF',
}
