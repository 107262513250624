import { useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Create'
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ControlledSelect } from 'src/components/Inputs/Controlled/Select'
import { graphql } from 'src/gql'
import { TigersMemberShowQuery } from 'src/gql/graphql'
import * as yup from 'yup'

import { useUpdateMember } from './useUpdateMember'

interface Props {
  member: TigersMemberShowQuery['findMember']
}

interface EligibilityInfoFormValues {
  firstName: string
  lastName: string
  birthDate: DateTime | null
  insuranceMemberId: string | null
  healthPlanId: number | null
}

const MIN_BIRTHDAY = DateTime.local().minus({ years: 13 }).startOf('day')

const HealthPlansQuery = graphql(`
  query HealthPlans {
    supportedHealthPlans {
      id
      name
    }
  }
`)

export const UpdateEligibilityInfo = ({ member }: Props) => {
  const [isEditing, setEditing] = useState<boolean>(false)
  const { updateEligibilityInfo } = useUpdateMember()

  const { data } = useQuery(HealthPlansQuery, { fetchPolicy: 'network-only' })
  const healthPlanOptions = data?.supportedHealthPlans.map(({ id, name }) => {
    return { value: id, label: name, key: name }
  })

  const defaultValues: EligibilityInfoFormValues = {
    firstName: member?.firstName ?? '',
    lastName: member?.lastName ?? '',
    birthDate: member?.birthDate?.toUTC() ?? null,
    insuranceMemberId: member?.healthPlanInfo?.insuranceId ?? null,
    healthPlanId: member?.healthPlanInfo?.healthPlan?.id ?? null,
  }

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(schema) })

  if (!member) return null

  const onSubmit = async ({
    birthDate,
    firstName,
    lastName,
    insuranceMemberId,
    healthPlanId,
  }: EligibilityInfoFormValues) => {
    if (!birthDate) return

    const { data } = await updateEligibilityInfo({
      birthDate,
      firstName,
      lastName,
      insuranceMemberId,
      healthPlanId,
      memberId: member.id,
    })
    if (data?.updateEligibilityInfo.success) resetForm()
  }

  const resetForm = () => {
    reset(defaultValues)
    setEditing(false)
  }

  const handleEdit = () => {
    reset(defaultValues)
    setEditing(true)
  }

  return (
    <>
      {isEditing ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display="flex"
            flexDirection="column"
            marginY={t => t.spacing(2)}
            sx={t => ({
              '& > *:not(:last-child)': { mb: t.spacing(2) },
            })}>
            <Box display="flex" flexDirection="row">
              <FormControl
                fullWidth
                sx={t => ({
                  mr: t.spacing(2),
                })}>
                <TextField
                  {...register('firstName')}
                  label="First Name"
                  variant="outlined"
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  {...register('lastName')}
                  label="Last Name"
                  variant="outlined"
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="birthDate"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      value={value}
                      onChange={date => onChange(date)}
                      disableFuture
                      openTo="year"
                      views={['year', 'month', 'day']}
                      format="MM/dd/yyyy"
                      label="DOB"
                      slotProps={{
                        textField: {
                          error: !!errors.birthDate,
                          helperText: errors.birthDate?.message,
                          value,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl fullWidth>
                <TextField
                  {...register('insuranceMemberId')}
                  label="Member Insurance ID"
                  variant="outlined"
                  error={!!errors.insuranceMemberId}
                  helperText={errors.insuranceMemberId?.message}
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection="row">
              {healthPlanOptions && (
                <ControlledSelect
                  control={control}
                  name="healthPlanId"
                  label="Health Plan"
                  options={healthPlanOptions}
                  fullWidth
                  error={errors.healthPlanId}
                />
              )}
              <Box display="flex" alignItems="center">
                <IconButton
                  type="submit"
                  disabled={isSubmitting}
                  title="Confirm"
                  size="small">
                  <CheckIcon htmlColor={'green'} />
                </IconButton>
                <IconButton title="Reset" onClick={resetForm} size="small">
                  <CloseIcon htmlColor="red" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </form>
      ) : (
        <Box
          marginY={t => t.spacing(2)}
          display="flex"
          justifyContent="flex-end">
          <Button
            size="small"
            endIcon={<EditIcon />}
            onClick={handleEdit}
            variant="outlined">
            Update Eligibility
          </Button>
        </Box>
      )}
      <Divider />
    </>
  )
}

const schema = yup.object().shape({
  firstName: yup.string().required('Required').trim(),
  lastName: yup.string().required('Required').trim(),
  birthDate: yup
    .mixed<DateTime>()
    .nullable()
    .test(value => (value ? value < MIN_BIRTHDAY : true))
    .required('Required'),
  insuranceMemberId: yup
    .string()
    .nullable()
    .matches(
      new RegExp('^[a-zA-Z0-9-]{6,15}$'),
      'Must include between 6 and 15 alphanumeric characters',
    ),
  healthPlanId: yup.number().when('insuranceMemberId', {
    is: (insuranceMemberId: string) => !!insuranceMemberId,
    then: schema => schema.required('Required when Insurance ID is set'),
    otherwise: schema => schema.nullable(),
  }),
})
