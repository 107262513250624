import { withScalars } from 'apollo-link-scalars'
import { buildClientSchema } from 'graphql'
import { DateTime } from 'luxon'
import introspectionResult from 'src/gql/graphql.schema.json'

const schema = buildClientSchema(introspectionResult)

const parseValue = (raw?: unknown) =>
  raw ? DateTime.fromISO(raw as string) : null

export const typesMap = {
  DateTime: {
    serialize: (parsed?: unknown) =>
      parsed ? (parsed as DateTime).toISO() : null,
    parseValue,
  },
  Date: {
    serialize: (parsed?: unknown) =>
      parsed ? (parsed as DateTime).toISODate() : null,
    parseValue,
  },
}

export const customScalars = withScalars({ schema, typesMap })
