import { Context } from '@apollo/client'
import { ContextSetter, setContext } from '@apollo/client/link/context'
import { Settings, Zone } from 'luxon'
import { getSlice } from 'src/store/getSlice'
import { selectTestMode } from 'src/store/selectors/testMode'

export const contextSetter: ContextSetter = (_, context: Context) => {
  const testMode = getSlice(selectTestMode)

  return {
    ...context,
    headers: {
      ...context.headers,
      'x-test-mode': testMode,
      'x-time-zone': (Settings.defaultZone as Zone).name,
    },
  }
}

export const context = setContext(contextSetter)
