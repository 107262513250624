import { useMutation, useQuery } from '@apollo/client'
import { NotificationsOutlined } from '@mui/icons-material'
import { Badge, IconButton, List, Popover } from '@mui/material'
import { useState } from 'react'
import { useInView } from 'react-cool-inview'
import { graphql } from 'src/gql'
import { NotificationFragment } from 'src/gql/graphql'

import { NotificationItem } from './NotificationItem'

const NotificationsQuery = graphql(`
  query Notifications($input: QueryDashboardNotificationsInput) {
    dashboardNotifications(input: $input) {
      notifications {
        ...Notification
      }
      totalUnseenCount
      cursor
    }
  }
`)

const MarkNotificationsAsSeenMutation = graphql(`
  mutation MarkNotificationsAsSeen($ids: [Int!]!) {
    markDashboardNotificationsAsSeen(ids: $ids) {
      success
      notifications {
        id
        seen
      }
    }
  }
`)

export const NotificationCenter: React.FC = () => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const { data, fetchMore, refetch } = useQuery(NotificationsQuery)
  const [mutate] = useMutation(MarkNotificationsAsSeenMutation, {
    onCompleted: ({ markDashboardNotificationsAsSeen: { success } }) => {
      if (success) refetch()
    },
  })
  const notifications = data?.dashboardNotifications.notifications ?? []
  const cursor = data?.dashboardNotifications.cursor
  const unseenCount = data?.dashboardNotifications.totalUnseenCount ?? 0

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchor(event.currentTarget)

  const handleClose = async () => {
    setAnchor(null)
    const _notifications = notifications as NotificationFragment[]
    if (_notifications.some(({ seen }) => !seen))
      await mutate({ variables: { ids: _notifications.map(({ id }) => id) } })
  }

  const { observe } = useInView({
    rootMargin: '50px 0px',
    onEnter({ unobserve }) {
      if (!cursor) return

      unobserve()

      fetchMore({
        variables: {
          input: { cursor: data?.dashboardNotifications.cursor },
        },
      })
    },
  })

  return (
    <>
      <IconButton
        sx={{ marginRight: t => t.spacing(0.5) }}
        edge="end"
        onClick={handleOpen}
        color="inherit"
        data-testid="notifications"
        size="large">
        <Badge badgeContent={unseenCount} color="error">
          <NotificationsOutlined style={{ fontSize: 24 }} />
        </Badge>
      </IconButton>
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handleClose}>
        <List
          sx={{
            width: 500,
            maxHeight: '40vh',
            overflow: 'auto',
            '& > *:not(:last-child)': {
              borderBottom: t => `1px solid ${t.palette.grey[300]}`,
            },
          }}>
          {notifications.map((notification, i) => (
            <NotificationItem
              key={(notification as NotificationFragment).id}
              handleClose={handleClose}
              notification={notification}
              ref={i === notifications.length - 5 ? observe : null}
            />
          ))}
        </List>
      </Popover>
    </>
  )
}
