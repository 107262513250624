import { ApolloClient, InMemoryCache } from '@apollo/client'

import { link } from './link'

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      RecurrenceV2: { keyFields: ['uuid'] },
      Feature: { keyFields: ['key'] },
      FeatureOverride: { keyFields: ['key'] },
      Query: {
        fields: {
          dashboardNotifications: {
            keyArgs: false,
            merge(existing, incoming) {
              return existing
                ? {
                    ...existing,
                    ...incoming,
                    notifications: [
                      ...existing.notifications,
                      ...incoming.notifications,
                    ],
                  }
                : incoming
            },
          },
        },
      },
    },
  }),
  link,
  connectToDevTools: true,
  name: 'dashboard',
  version: import.meta.env.VITE_RELEASE_ID,
})
