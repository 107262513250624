import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ConfirmationDialog } from 'src/components/ConfirmationDialog'
import { graphql } from 'src/gql'
import { clearSelectedChannel } from 'src/store/slices/messaging'
import { useAppDispatch } from 'src/store/useAppDispatch'

const LeaveChannelMutation = graphql(`
  mutation LeaveChannel($url: String!) {
    removeDashboardUserFromChannel(url: $url) {
      success
      message
    }
  }
`)

export const LeaveConversationButton = () => {
  const { channelUrl } = useGroupChannelContext()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [leaveChannel] = useMutation(LeaveChannelMutation, {
    onCompleted: ({ removeDashboardUserFromChannel: { success, message } }) => {
      enqueueSnackbar(message, { variant: success ? 'success' : 'error' })
      setOpen(false)
      if (!success) return
      dispatch(clearSelectedChannel())
      if (pathname.includes('messages')) navigate('/peer-portal/messages')
    },
    onError: () => {
      enqueueSnackbar('Oops, something went wrong.', { variant: 'error' })
    },
  })

  const handleConfirmationClose = async (confirm: boolean) => {
    if (!confirm) setOpen(false)
    else await leaveChannel({ variables: { url: channelUrl } })
  }

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        color="error"
        onClick={() => setOpen(true)}>
        Leave Conversation
      </Button>
      <ConfirmationDialog
        title="Are you sure?"
        message="Once you leave this conversation you will no longer be able to view messages from this member."
        open={open}
        onClose={handleConfirmationClose}
      />
    </>
  )
}
