import { useQuery } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Grid, IconButton, Link, List, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'
import { AccountStatusChip } from 'src/components/AccountStatusChip'
import { LabeledListItem } from 'src/components/LabeledListItem'
import { ListHeading } from 'src/components/ListHeading'
import { Spinner } from 'src/components/Spinner'
import { getFullName } from 'src/functions/members'
import { graphql } from 'src/gql'

import { EditInput } from './EditInput'
import { Notes } from './Notes'
import { SelectContract } from './SelectContract'
import { UpdateEligibilityInfo } from './UpdateEligibilityInfo'
import { useUpdateMember } from './useUpdateMember'

const TigersMemberShowQuery = graphql(`
  query TigersMemberShow($memberId: Int!) {
    findMember(memberId: $memberId) {
      id
      name
      firstName
      lastName
      email
      phoneNumber
      accountStatus
      birthDate
      csNotes
      challenges(take: 1) {
        id
      }
      treatmentProvider {
        id
        name
      }
      benefits {
        id
        contract {
          id
          name
        }
      }
      rewardsCode {
        id
        code
        createdAt
        distributionMethod
      }
      rewardsPlan {
        id
        name
      }
      healthPlanInfo {
        id
        insuranceId
        healthPlan {
          id
          name
        }
      }
    }
  }
`)

export const MemberShow = () => {
  const { memberId } = useParams() as { memberId: string }
  const navigate = useNavigate()

  const { data, loading } = useQuery(TigersMemberShowQuery, {
    variables: { memberId: +memberId },
  })
  const member = data?.findMember
  const { updatePhoneNumber, validatePhoneNumber } = useUpdateMember()

  if (loading) return <Spinner />
  if (!member) return null

  return (
    <Box minWidth={450}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          variant="h6">
          {getFullName(member)}
        </Typography>
        <IconButton onClick={() => navigate(-1)} size="large">
          <CloseIcon data-testid="close-show-member" />
        </IconButton>
      </Box>
      <Grid
        container
        justifyContent="space-between"
        sx={{ mt: t => t.spacing(2) }}>
        <Typography variant="body2" color="textSecondary">
          ID: {member.id}
        </Typography>
        <Link
          component={RouterLink}
          to={`/peer-portal/members/${member.id}`}
          sx={{ float: 'right', color: t => t.palette.linkBlue.main }}>
          Member Details
        </Link>
      </Grid>
      <ListHeading>Member Information</ListHeading>
      <List>
        <LabeledListItem label="Account status">
          <AccountStatusChip accountStatus={member.accountStatus} />
        </LabeledListItem>
        <EditInput
          memberId={member.id}
          initialValue={member.phoneNumber ?? ''}
          allowEmpty={true}
          onSubmit={updatePhoneNumber}
          title="Member phone number"
          validate={validatePhoneNumber}
        />
        <LabeledListItem label="Email">{member.email}</LabeledListItem>
        <LabeledListItem label="DOB">
          {member.birthDate?.toUTC().toLocaleString(DateTime.DATE_SHORT) ??
            'Not set'}
        </LabeledListItem>
        <LabeledListItem label="Contract Name" divider={false}>
          {member.benefits?.contract?.name || (
            <SelectContract memberId={member.id} />
          )}
        </LabeledListItem>
      </List>
      {member.treatmentProvider && (
        <>
          <ListHeading>Treatment Provider</ListHeading>
          <List>
            <LabeledListItem label="Name">
              {member.treatmentProvider.name}
            </LabeledListItem>
          </List>
        </>
      )}
      {member.benefits && (
        <>
          <ListHeading>Benefits</ListHeading>
          <List>
            <LabeledListItem label="Plan">
              {member.rewardsPlan?.name}
            </LabeledListItem>
          </List>
        </>
      )}
      {member.rewardsCode && (
        <>
          <ListHeading>Rewards Code</ListHeading>
          <List>
            <LabeledListItem label="Code">
              {member.rewardsCode.code}
            </LabeledListItem>
            <LabeledListItem label="Code Created">
              {member.rewardsCode.createdAt.toFormat('MM/dd/yyyy')}
            </LabeledListItem>
            <LabeledListItem label="Distribution Method">
              {member.rewardsCode.distributionMethod}
            </LabeledListItem>
          </List>
        </>
      )}
      {member.healthPlanInfo ? (
        <>
          <ListHeading>Health Plan</ListHeading>
          <List>
            <LabeledListItem label="Name">
              {member.healthPlanInfo.healthPlan.name}
            </LabeledListItem>
          </List>
          <List>
            <LabeledListItem divider={false} label="Insurance ID">
              {member.healthPlanInfo.insuranceId}
            </LabeledListItem>
          </List>
        </>
      ) : null}
      <UpdateEligibilityInfo member={member} />
      <ListHeading>Notes</ListHeading>
      <Notes member={member} />
    </Box>
  )
}
