import { DateTime } from 'luxon'
import pluralize from 'pluralize'

type MemberOrUser = {
  name?: string | null
  firstName?: string | null
  lastName?: string | null
  uuid?: string
  id?: number
}

export const getFullName = ({
  name,
  firstName,
  lastName,
  id,
  uuid,
}: MemberOrUser): string => {
  if (name) return name
  if (firstName && lastName) return `${firstName} ${lastName}`

  return `Member ${id ?? uuid}`
}

export const daysInProgress = (progressDate?: DateTime | null) => {
  if (!progressDate) return 'N/A'

  const days = Math.abs(Math.round(progressDate.diffNow('days').days))
  return `${days} ${pluralize('day', days)}`
}
