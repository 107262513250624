import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Create'
import {
  FormControl,
  FormHelperText,
  IconButton,
  Input as BaseInput,
  InputAdornment,
  InputLabel,
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import React, { useEffect, useRef, useState } from 'react'

import { ValidationResponse } from './useUpdateMember'

export interface Props {
  memberId: number
  initialValue: string | null
  allowEmpty?: boolean
  onSubmit: (
    memberId: number,
    value: string | null,
    onSuccess: () => void,
    onFailure: (error: string) => void,
  ) => void
  title: string
  validate: (newValue: string) => ValidationResponse | null
}

export const Input = withStyles({
  root: {
    '&.MuiInputBase-root.Mui-disabled': {
      color: 'black',
    },
  },
})(BaseInput)

export const EditInput: React.FC<Props> = ({
  memberId,
  initialValue,
  onSubmit,
  title,
  validate,
  allowEmpty,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [validation, setValidation] = useState<ValidationResponse | null>(null)
  const [value, setValue] = useState<string | null>(initialValue)
  const [isEditing, setEditing] = useState<boolean>(false)

  const resetValue = () => {
    setValue(initialValue)
    setValidation(null)
    setEditing(false)
  }

  const onConfirm = () => {
    if (value || allowEmpty)
      onSubmit(
        memberId,
        value,
        () => {
          setEditing(false)
          setValidation(null)
        },
        error => {
          setValidation({ isError: true, message: error })
        },
      )
  }

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus()
    }
  }, [isEditing, title])

  const id = `EditInput-${title}`

  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel htmlFor={id} shrink={true}>
        {title}
      </InputLabel>
      <Input
        id={id}
        onChange={event => {
          const newValue = event.target.value
          setValue(newValue)
          setValidation(validate(newValue))
        }}
        placeholder={'Not set'}
        disabled={!isEditing}
        inputRef={inputRef}
        value={value}
        error={validation?.isError}
        endAdornment={
          <InputAdornment position="end">
            {isEditing ? (
              <>
                <IconButton
                  title="Confirm"
                  disabled={validation?.isError}
                  onClick={onConfirm}
                  size="small">
                  <CheckIcon
                    htmlColor={validation?.isError ? undefined : 'green'}
                  />
                </IconButton>
                <IconButton title="Reset" onClick={resetValue} size="small">
                  <CloseIcon htmlColor="red" />
                </IconButton>
              </>
            ) : (
              <IconButton
                title="Edit"
                onClick={() => setEditing(true)}
                size="small">
                <EditIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
      />
      <FormHelperText error={validation?.isError}>
        {validation?.message}
      </FormHelperText>
    </FormControl>
  )
}
