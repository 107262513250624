/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType } from 'react'

export const retry = (
  fn: () => Promise<any>,
  onFailure: Function,
  retriesLeft = 5,
  interval = 1000,
) => {
  return new Promise<{ default: ComponentType<unknown> }>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: unknown) => {
        if (retriesLeft === 1) {
          onFailure ? onFailure() : reject(error)
          return
        }

        setTimeout(() => {
          retry(fn, onFailure, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export const retryThenReload = (
  fn: () => Promise<{ default: React.FC<any> }>,
) => retry(fn, () => document.location.reload(), 1)
