import { createTheme, lighten, PaletteColor } from '@mui/material/styles'

import {
  borderGray,
  errorColor,
  gunmetalGray,
  headerFontColor,
  infoColor,
  linkBlue,
  lovelyPurple,
  primaryColor,
  secondaryColor,
  successColor,
  warningColor,
  white,
} from './colors'

declare module '@mui/material/styles' {
  interface Palette {
    borderGray: PaletteColor
    gunmetalGray: PaletteColor
    linkBlue: PaletteColor
    lovelyPurple: PaletteColor
    white: PaletteColor
  }

  interface PaletteOptions {
    borderGray: PaletteColor
    gunmetalGray: PaletteColor
    linkBlue: PaletteColor
    lovelyPurple: PaletteColor
    white: PaletteColor
    secondaryText: PaletteColor
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    lovelyPurple: true
    secondaryText: true
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    gunmetalGray: true
  }
}

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } })

const largeHeaderBase = {
  fontFamily: "'Roboto Slab', serif",
  fontWeight: 600,
  color: headerFontColor,
}

const smallHeaderBase = {
  fontWeight: 600,
  color: lighten(headerFontColor, 0.2),
}

export const theme = createTheme({
  spacing: 5,
  palette: {
    primary: createColor(primaryColor),
    secondary: createColor(secondaryColor),
    error: createColor(errorColor),
    warning: createColor(warningColor),
    info: createColor(infoColor),
    success: createColor(successColor),
    borderGray: createColor(borderGray),
    gunmetalGray: createColor(gunmetalGray),
    linkBlue: createColor(linkBlue),
    lovelyPurple: createColor(lovelyPurple),
    white: createColor(white),
    secondaryText: createColor(palette.text.secondary),
  },
  shape: { borderRadius: 6 },
  typography: {
    h1: { ...largeHeaderBase, fontSize: 36 },
    h2: { ...largeHeaderBase, fontSize: 30 },
    h3: { ...largeHeaderBase, fontSize: 26 },
    h4: { ...smallHeaderBase, fontSize: 24 },
    h5: { ...smallHeaderBase, fontSize: 20 },
    h6: { ...smallHeaderBase, fontSize: 18 },
    button: { textTransform: 'none' },
  },
  components: {
    MuiButtonBase: { defaultProps: { disableRipple: true } },
    MuiTable: { defaultProps: { size: 'small' } },
  },
})
