import * as Sentry from '@sentry/react'
import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { retryThenReload } from 'src/functions/retry'
import { useLogoutOnInactivity } from 'src/hooks/useLogoutOnInactivity'
import { AdminLayout } from 'src/screens/Admin/Layout'
import { NotFound } from 'src/screens/NotFound'
import { PeerPortalLayout } from 'src/screens/PeerPortal/Layout'
import { MembersProvider } from 'src/screens/PeerPortal/Members/MembersContext'
import { Conversation } from 'src/screens/PeerPortal/Messages/Conversation'
import { SettingsLayout } from 'src/screens/Settings/Layout'
import { MembersShowModal } from 'src/screens/Tiger/Members/MembersShowModal'

const adminNavLinks = [
  { link: 'contracts', label: 'Contracts' },
  { link: 'users', label: 'Users' },
  { link: 'features', label: 'Feature Flags' },
]

const tigersNavLinks = [
  { link: 'members', label: 'Members' },
  { link: 'codes', label: 'Rewards Codes' },
  { link: 'plans', label: 'Rewards Plans' },
  { link: 'eligibility-check', label: 'Eligibility Check' },
  { link: 'member-feedback', label: 'Member Feedback' },
]

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

interface Props {
  originalPath: string
}

export const AuthenticatedRoutes = ({ originalPath }: Props) => {
  useLogoutOnInactivity()

  return (
    <SentryRoutes>
      {/* Authenticated Routes */}
      <Route
        path="admin/*"
        element={<AdminLayout headerMode="admin" navLinks={adminNavLinks} />}>
        <Route index element={<Navigate to="contracts" />} />
        <Route path="contracts">
          <Route index element={<Contracts />} />
          <Route path=":contractId" element={<Contract />} />
        </Route>
        <Route path="users" element={<Users />} />
        <Route path="features" element={<Features />} />
      </Route>

      <Route
        path="tiger/*"
        element={<AdminLayout headerMode="tiger" navLinks={tigersNavLinks} />}>
        <Route path="members" element={<TigersMembers />}>
          <Route path="member/:memberId" element={<MembersShowModal />} />
        </Route>
        <Route path="codes" element={<Codes />} />
        <Route path="codes/code/:codeId" element={<CodeShow />} />
        <Route path="plans" element={<RewardsPlans />} />
        <Route path="eligibility-check" element={<EligibilityCheck />} />
        <Route path="member-feedback" element={<MemberFeedback />} />
        <Route index element={<Navigate to="members" />} />
      </Route>

      <Route path="peer-portal/*" element={<PeerPortalLayout />}>
        <Route index element={<Navigate replace={true} to="members" />} />
        <Route path="members">
          <Route
            index
            element={
              <MembersProvider>
                <Members />
              </MembersProvider>
            }
          />
          <Route path=":memberId" element={<Member />} />
          <Route path=":memberId/engagements" element={<Engagements />} />
          <Route path=":memberId/wellness-plan" element={<WellnessPlan />} />
        </Route>
        <Route path="messages" element={<Messages />}>
          <Route path=":channelUrl" element={<Conversation />} />
        </Route>
        <Route path="deleted-messages" element={<DeletedMessages />} />
        <Route path="peers">
          <Route index element={<Peers />} />
          <Route path=":peerId" element={<Peer />} />
        </Route>
      </Route>
      <Route path="settings" element={<SettingsLayout />}></Route>
      <Route
        path="login"
        element={
          <Navigate
            to={
              originalPath === '/login' ? '/peer-portal/members' : originalPath
            }
          />
        }
      />
      <Route index element={<Navigate to="/peer-portal/members" />} />
      {/* 404 */}
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  )
}

const Users = lazy(() =>
  retryThenReload(() =>
    import('../screens/Admin/Users').then(({ Users }) => ({
      default: Users,
    })),
  ),
)

const Contracts = lazy(() =>
  retryThenReload(() =>
    import('../screens/Admin/Contracts').then(({ Contracts }) => ({
      default: Contracts,
    })),
  ),
)

const Contract = lazy(() =>
  retryThenReload(() =>
    import('../screens/Admin/Contract').then(({ Contract }) => ({
      default: Contract,
    })),
  ),
)

const Features = lazy(() =>
  import('../screens/Admin/Features').then(({ Features }) => ({
    default: Features,
  })),
)

const TigersMembers = lazy(() =>
  retryThenReload(() =>
    import('../screens/Tiger/Members').then(({ Members }) => ({
      default: Members,
    })),
  ),
)

const RewardsPlans = lazy(() =>
  retryThenReload(() =>
    import('../screens/Tiger/RewardsPlans').then(({ RewardsPlans }) => ({
      default: RewardsPlans,
    })),
  ),
)

const MemberFeedback = lazy(() =>
  retryThenReload(() =>
    import('../screens/Tiger/MemberFeedback').then(({ MemberFeedback }) => ({
      default: MemberFeedback,
    })),
  ),
)

const EligibilityCheck = lazy(() =>
  retryThenReload(() =>
    import('../screens/Tiger/EligibilityCheck').then(
      ({ EligibilityCheck }) => ({
        default: EligibilityCheck,
      }),
    ),
  ),
)
const Codes = lazy(() =>
  retryThenReload(() =>
    import('../screens/Tiger/Codes').then(({ Codes }) => ({
      default: Codes,
    })),
  ),
)

const CodeShow = lazy(() =>
  retryThenReload(() =>
    import('../screens/Tiger/Codes/CodeShow').then(({ CodeShow }) => ({
      default: CodeShow,
    })),
  ),
)

const Member = lazy(() =>
  retryThenReload(() =>
    import('../screens/PeerPortal/Member').then(({ Member }) => ({
      default: Member,
    })),
  ),
)

const Engagements = lazy(() =>
  retryThenReload(() =>
    import('../screens/PeerPortal/Engagements').then(({ Engagements }) => ({
      default: Engagements,
    })),
  ),
)

const Members = lazy(() =>
  retryThenReload(() =>
    import('../screens/PeerPortal/Members').then(({ Members }) => ({
      default: Members,
    })),
  ),
)

const Peers = lazy(() =>
  retryThenReload(() =>
    import('../screens/PeerPortal/Peers').then(({ Peers }) => ({
      default: Peers,
    })),
  ),
)

const Peer = lazy(() =>
  retryThenReload(() =>
    import('../screens/PeerPortal/Peer').then(({ Peer }) => ({
      default: Peer,
    })),
  ),
)

const Messages = lazy(() =>
  retryThenReload(() =>
    import('../screens/PeerPortal/Messages').then(({ Messages }) => ({
      default: Messages,
    })),
  ),
)

const DeletedMessages = lazy(() =>
  retryThenReload(() =>
    import('../screens/PeerPortal/DeletedMessages').then(
      ({ DeletedMessages }) => ({
        default: DeletedMessages,
      }),
    ),
  ),
)

const WellnessPlan = lazy(() =>
  retryThenReload(() =>
    import('../screens/PeerPortal/WellnessPlan').then(({ WellnessPlan }) => ({
      default: WellnessPlan,
    })),
  ),
)
