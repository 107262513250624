import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ColumnKey =
  | 'name'
  | 'benefitsActivatedAt'
  | 'peerSupportQuestionnaireTaken'
  | 'lastCheckIn'
  | 'peerAssignment'
  | 'contractName'
  | 'maxPotentialRewards'
  | 'messagingEndsAt'
  | 'messageCount'
  | 'lastMessageReadAt'
  | 'lastMessageSentAt'
  | 'lastMessageSentBy'
  | 'wellnessPlanComplete'
  | 'totalEngagementDuration'
  | 'okToContact'
  | 'language'

const initialState = {
  members: {
    columns: {
      name: true,
      benefitsActivatedAt: true,
      contractName: true,
      maxPotentialRewards: true,
      lastCheckIn: true,
      messagingEndsAt: true,
      messageCount: false,
      lastMessageReadAt: false,
      lastMessageSentAt: false,
      lastMessageSentBy: true,
      wellnessPlanComplete: false,
      totalEngagementDuration: false,
      okToContact: false,
      peerSupportQuestionnaireTaken: true,
      peerAssignment: true,
      language: true,
    } as Record<ColumnKey, boolean>,
  },
}

const peerPortal = createSlice({
  name: 'peerPortal',
  initialState,
  reducers: {
    setActiveColumns: (state, { payload }: PayloadAction<ColumnKey[]>) => {
      Object.keys(state.members.columns).forEach(key => {
        state.members.columns[key as ColumnKey] = payload.includes(
          key as ColumnKey,
        )
      })
    },
  },
})

export const { setActiveColumns } = peerPortal.actions
export const { reducer } = peerPortal
