import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid, Link } from '@mui/material'
import qs from 'qs'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { PasswordInput } from 'src/components/Inputs/PasswordInput'
import { useResetPassword } from 'src/screens/Auth/useResetPassword'
import { setServer2020Token } from 'src/store/slices/auth'
import { useAppDispatch } from 'src/store/useAppDispatch'
import * as yup from 'yup'

import { AuthForm } from './AuthForm'

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Required')
    .min(6, 'Must be 6 characters or longer'),
})

export const ResetPassword: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { resetPassword } = useResetPassword()
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<{ password: string }>({
    defaultValues: { password: '' },
    resolver: yupResolver(schema),
  })
  const token =
    qs.parse(location.search, { ignoreQueryPrefix: true }).token?.toString() ??
    ''

  useEffect(() => {
    dispatch(setServer2020Token(token))
  }, [dispatch, token])

  return (
    <AuthForm onSubmit={handleSubmit(resetPassword)}>
      <PasswordInput control={control} name="password" />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        sx={{ margin: t => t.spacing(2, 0) }}
        disabled={isSubmitting}>
        {isSubmitting ? 'Resetting password...' : 'Reset password'}
      </Button>
      <Grid container>
        <Grid item xs>
          <Link
            href="#"
            onClick={(): void => navigate(`/login`)}
            variant="body2">
            &larr; Back to sign in
          </Link>
        </Grid>
      </Grid>
    </AuthForm>
  )
}
