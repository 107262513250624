import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { PasswordInput } from 'src/components/Inputs/PasswordInput'
import * as yup from 'yup'

import { useUpdatePassword } from './useUpdatePassword'

interface PasswordResetForm {
  password: string
  newPassword: string
}

const schema = yup.object().shape({
  password: yup.string().required('Required'),
  newPassword: yup
    .string()
    .required('Required')
    .min(6, 'Must be at least 6 characters'),
})

export const UpdatePasswordForm: React.FC = () => {
  const { updatePassword, loading } = useUpdatePassword()
  const [visibility, setVisibility] = useState(false)
  const toggleVisibility = (): void => setVisibility(!visibility)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PasswordResetForm>({
    defaultValues: { password: '', newPassword: '' },
    resolver: yupResolver(schema),
  })

  return (
    <form onSubmit={handleSubmit(updatePassword)}>
      <PasswordInput
        name="password"
        control={control}
        label="Current Password"
        visibility={visibility}
        visibilityControl={toggleVisibility}
        error={!!errors.password}
        helperText={errors.password?.message}
      />
      <PasswordInput
        name="newPassword"
        control={control}
        label="New Password"
        visibility={visibility}
        visibilityControl={toggleVisibility}
        helperText={errors.newPassword?.message}
        error={!!errors.newPassword}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        sx={{ my: t => t.spacing(2) }}
        disabled={loading}>
        Update password
      </Button>
    </form>
  )
}
