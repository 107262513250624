import { Badge } from '@mui/material'
import {
  sendbirdSelectors,
  useSendbirdStateContext,
} from '@sendbird/uikit-react'
import UserEventHandler from '@sendbird/uikit-react/handlers/UserEventHandler'
import { ChildrenProp, useEffect, useId, useRef, useState } from 'react'

interface Props {
  children: ChildrenProp
}

export const TotalUnreadMessageCountBadge = ({ children }: Props) => {
  const [totalCount, setTotalCount] = useState(0)
  const initialCountSetRef = useRef(false)
  const sbContext = useSendbirdStateContext()
  const sdkInstance = sendbirdSelectors.getSdk(sbContext)
  const connectionHandlerId = useId()
  const userEventHandlerId = useId()

  useEffect(() => {
    const getInitialValue = async () => {
      if (!sdkInstance?.groupChannel || initialCountSetRef.current) return

      const count = await sdkInstance.groupChannel.getTotalUnreadMessageCount({
        channelCustomTypesFilter: ['treatmentCenter'],
      })
      setTotalCount(count)
      initialCountSetRef.current = true
    }

    getInitialValue()
  }, [sdkInstance?.groupChannel])

  useEffect(() => {
    if (!sdkInstance?.addUserEventHandler) return

    sdkInstance.addUserEventHandler(
      userEventHandlerId,
      new UserEventHandler({
        onTotalUnreadMessageCountUpdated(count) {
          setTotalCount(count)
        },
      }),
    )
    return () => {
      sdkInstance.removeUserEventHandler(userEventHandlerId)
      sdkInstance.removeConnectionHandler(connectionHandlerId)
    }
  }, [
    sdkInstance,
    sdkInstance?.addUserEventHandler,
    connectionHandlerId,
    userEventHandlerId,
  ])

  return (
    <Badge
      badgeContent={totalCount > 99 ? '99+' : totalCount}
      color="secondary">
      {children}
    </Badge>
  )
}
