import { Profiler } from 'react'
import { logProfiler } from 'src/common/logProfiler'
import { Header } from 'src/components/Header'

import { PageContainer } from '../PageContainer'
import { Settings } from '.'

export const SettingsLayout = () => {
  return (
    <Profiler id="Admin" onRender={logProfiler}>
      <Header dashboardMode="settings" />
      <PageContainer>
        <Settings />
      </PageContainer>
    </Profiler>
  )
}
