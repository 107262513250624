import { useMutation } from '@apollo/client'
import { isMobilePhone } from 'class-validator'
import { useSnackbar } from 'notistack'
import { graphql } from 'src/gql'
import {
  UpdateEligibilityInput,
  UpdateNotesInput,
  UpgradeByAdminInput,
} from 'src/gql/graphql'

export const NON_PHONE_CHARS = /[^\d+]+/g

export interface ValidationResponse {
  isError: boolean
  message: string
}

const UpdatePhoneNumber = graphql(`
  mutation UpdatePhoneNumber($input: UpdatePhoneNumberInput!) {
    updatePhoneNumber(input: $input) {
      memberV2 {
        id
        phoneNumber
      }
    }
  }
`)

const UpdateTreatmentProvider = graphql(`
  mutation UpdateMemberTreatmentProvider(
    $input: UpdateMemberTreatmentProviderInput!
  ) {
    updateMemberTreatmentProvider(input: $input) {
      id
      name
      treatmentProvider {
        id
        name
      }
    }
  }
`)

const UpgradeByAdmin = graphql(`
  mutation UpgradeByAdmin($input: UpgradeByAdminInput!) {
    upgradeByAdmin(input: $input) {
      member {
        ... on Member {
          id
          benefits {
            id
            maxPotentialRewards
            messagingEndsAt
            rewardsEndsAt
            contract {
              id
              name
            }
          }
          rewardsPlan {
            name
          }
        }
        ... on ValidationErrors {
          validationErrors {
            errors
            field
          }
        }
      }
      message
      success
    }
  }
`)

const UpdateEligibilityInfo = graphql(`
  mutation UpdateEligibilityInfo($input: UpdateEligibilityInput!) {
    updateEligibilityInfo(input: $input) {
      success
      message
      member {
        ... on Member {
          id
          name
          firstName
          lastName
          birthDate
          healthPlanInfo {
            id
            insuranceId
            healthPlan {
              id
              name
            }
          }
        }
      }
    }
  }
`)

const UpdateNotes = graphql(`
  mutation UpdateNotes($input: UpdateNotesInput!) {
    updateNotes(input: $input) {
      success
      message
      member {
        ... on Member {
          id
          csNotes
          mesNotes
        }
      }
    }
  }
`)

export const useUpdateMember = () => {
  const { enqueueSnackbar } = useSnackbar()

  const [updatePhoneMutate] = useMutation(UpdatePhoneNumber)

  const [updateTreatmentProviderMutate] = useMutation(UpdateTreatmentProvider)

  const [upgradeByAdminMutate] = useMutation(UpgradeByAdmin, {
    onCompleted: ({ upgradeByAdmin: { success, message, member } }) => {
      let flashMessage = message
      if (member?.__typename === 'ValidationErrors') {
        const healthPlanError = member.validationErrors.find(
          ({ field }) => field === 'healthPlanMembership',
        )
        if (healthPlanError) flashMessage = healthPlanError.errors.join()
      }
      enqueueSnackbar(flashMessage, { variant: success ? 'success' : 'error' })
    },
    onError: () => {
      enqueueSnackbar('Oops. Something went wrong.', { variant: 'error' })
    },
  })

  const [updateEligibilityMutate] = useMutation(UpdateEligibilityInfo, {
    onCompleted: ({ updateEligibilityInfo: { success, message } }) => {
      enqueueSnackbar(message, { variant: success ? 'success' : 'error' })
    },
    onError: () => {
      enqueueSnackbar('Oops. Something went wrong.', { variant: 'error' })
    },
  })

  const [updateNotesMutate] = useMutation(UpdateNotes, {
    refetchQueries: ['PeerPortalMembers', 'FindMember'],
    onCompleted: ({ updateNotes: { success, message } }) => {
      enqueueSnackbar(message, { variant: success ? 'success' : 'error' })
    },
    onError: () => {
      enqueueSnackbar('Oops. Something went wrong.', { variant: 'error' })
    },
  })

  const validatePhoneNumber = (phoneNumber: string) => {
    const trimmedPhoneNumber = phoneNumber.replace(NON_PHONE_CHARS, '')
    if (phoneNumber === '') {
      return {
        isError: false,
        message: 'Are you sure you want to clear the phone number?',
      }
    }
    return isMobilePhone(trimmedPhoneNumber)
      ? null
      : {
          isError: true,
          message: 'Must be a valid US phone number (ex: +1 123-456-7890)',
        }
  }

  const updatePhoneNumber = async (
    memberId: number,
    phoneNumber: string | null,
    onSuccess: () => void,
    onFailure: (error: string) => void,
  ) => {
    try {
      let newPhoneNumber: string | null = null

      if (phoneNumber && phoneNumber.length > 0) {
        newPhoneNumber = phoneNumber.replace(NON_PHONE_CHARS, '')
      }

      await updatePhoneMutate({
        variables: { input: { userId: memberId, newPhoneNumber } },
      })

      enqueueSnackbar('Successfully updated phone number', {
        variant: 'success',
      })
      onSuccess()
    } catch {
      const errorMsg = 'Error updating phone number'
      enqueueSnackbar(errorMsg, { variant: 'error' })
      onFailure(errorMsg)
    }
  }

  const updateTreatmentProvider = async (
    memberId: number,
    treatmentProviderId: number | null,
  ) => {
    try {
      const { data } = await updateTreatmentProviderMutate({
        variables: { input: { treatmentProviderId, memberId } },
      })

      enqueueSnackbar(
        `Successfully updated the treatment provider for ${data?.updateMemberTreatmentProvider?.name}.`,
        { variant: 'success' },
      )
    } catch {
      enqueueSnackbar(`Oops, something went wrong. Please try again.`, {
        variant: 'error',
      })
    }
  }

  const upgradeByAdmin = async (input: UpgradeByAdminInput) =>
    upgradeByAdminMutate({ variables: { input } })

  const updateEligibilityInfo = async (input: UpdateEligibilityInput) =>
    updateEligibilityMutate({ variables: { input } })

  const updateNotes = async (input: UpdateNotesInput) =>
    updateNotesMutate({ variables: { input } })

  return {
    updatePhoneNumber,
    validatePhoneNumber,
    updateTreatmentProvider,
    upgradeByAdmin,
    updateEligibilityInfo,
    updateNotes,
  }
}
