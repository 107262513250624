import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { login, logout } from 'src/store/thunks/auth'
import { SendbirdToken, User } from 'src/types'

interface AuthState {
  user?: User | null
  sendbirdToken?: SendbirdToken | null
  server2020Token?: string | null
  loading: boolean
  error?: string | null
}

const initialState: AuthState = {
  user: null,
  loading: false,
  error: null,
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setServer2020Token: (
      state,
      { payload }: { payload: string | undefined },
    ) => {
      state.server2020Token = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(login.pending, state => {
      state.loading = true
    })
    builder.addCase(
      login.fulfilled,
      (state, { payload }: PayloadAction<Partial<AuthState>>) => {
        state.user = payload.user
        state.error = payload.error
        state.sendbirdToken = payload.sendbirdToken
        state.server2020Token = payload.server2020Token
        state.loading = false
        state.error = payload.error
      },
    )
    builder.addCase(login.rejected, state => {
      state.error = 'Email or password not recognized'
      state.loading = false
    })
    builder.addCase(logout.pending, state => {
      state.loading = true
    })
    builder.addCase(logout.fulfilled, state => {
      state.user = null
      state.error = null
      state.sendbirdToken = undefined
      state.server2020Token = undefined
      state.error = null
      state.loading = false
    })
    builder.addCase(logout.rejected, state => {
      state.user = null
      state.error = null
      state.sendbirdToken = undefined
      state.server2020Token = undefined
      state.error = null
      state.loading = false
    })
  },
})

export const { setServer2020Token } = auth.actions

export const { reducer } = auth
