import { AppBar, Box, Toolbar, Typography, useTheme } from '@mui/material'
import React from 'react'
import { DashboardSelector } from 'src/components/Header/DashboardSelector'
import { NavMainMenu } from 'src/components/Header/NavMainMenu'
import { ResponsiveLogo } from 'src/components/Header/ResponsiveLogo'
import { selectTestMode } from 'src/store/selectors/testMode'
import { useAppSelector } from 'src/store/useAppSelector'
import {
  adminColor,
  loadingColor,
  peerPortalColor,
  settingsColor,
  tigerColor,
} from 'src/styles/colors'
import { BaseLink, DashboardMode } from 'src/types'

import { AccountMenu } from './AccountMenu'
import { NotificationCenter } from './NotificationCenter'

const getDashboardColor = (dashboardMode: DashboardMode): string =>
  ({
    admin: adminColor,
    tiger: tigerColor,
    loading: loadingColor,
    peerPortal: peerPortalColor,
    settings: settingsColor,
  }[dashboardMode])

interface Props {
  dashboardMode: DashboardMode
  accessoryLeft?: React.ReactNode
  navLinks?: BaseLink[]
}

export const Header: React.FC<Props> = ({
  dashboardMode,
  navLinks,
  accessoryLeft = React.Fragment,
}) => {
  const theme = useTheme()

  const testMode = useAppSelector(selectTestMode)

  return (
    <Box>
      <AppBar
        position="static"
        elevation={0}
        sx={{ color: getDashboardColor(dashboardMode) }}>
        <Toolbar
          sx={{
            backgroundColor: '#fafafa',
            borderBottom: testMode
              ? `2px solid ${theme.palette.error.main}`
              : 'none',
            py: theme.spacing(2),
            px: theme.spacing(4),
          }}>
          <>
            <Box
              mr={theme.spacing(2)}
              sx={{
                [theme.breakpoints.up('lg')]: { marginRight: theme.spacing(4) },
              }}>
              <ResponsiveLogo />
            </Box>
            <DashboardSelector dashboardMode={dashboardMode} />
            {accessoryLeft}
            <NavMainMenu
              navLinks={navLinks}
              color={getDashboardColor(dashboardMode)}
            />
            {dashboardMode === 'peerPortal' && <NotificationCenter />}
            <AccountMenu dashboardMode={dashboardMode} />
          </>
        </Toolbar>
        {testMode && (
          <Box
            bgcolor={theme.palette.white.main}
            color="white"
            display="flex"
            justifyContent="center">
            <Typography
              px={theme.spacing(1)}
              bgcolor={theme.palette.error.main}
              color="#FAFAFA"
              borderRadius={`0px 0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`}
              variant="caption">
              Test Mode
            </Typography>
          </Box>
        )}
      </AppBar>
    </Box>
  )
}
