import { ApolloLink } from '@apollo/client'

import { Subscriber } from './types'

export const subscribable = new ApolloLink((operation, forward) => {
  if (!forward) throw new Error('Cannot be a terminating link')

  const subscribers: Subscriber[] = operation.getContext().subscribers ?? []

  return subscribers.reduce(
    (acc$, subscriber) => subscriber(operation, acc$),
    forward(operation),
  )
})
