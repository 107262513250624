import { Circle } from '@mui/icons-material'
import { Box, ListItem, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { FragmentType, graphql, useFragment } from 'src/gql'
import { DashboardNotificationEvent } from 'src/gql/graphql'

const timestamp = (dt: DateTime) => {
  const { minutes, hours, days, seconds } = DateTime.local().diff(
    dt,
    ['days', 'hours', 'minutes', 'seconds'],
    { conversionAccuracy: 'casual' },
  )
  if (days > 0) return `${days}d `
  if (hours > 0) return `${hours}h `
  if (minutes > 0) return `${minutes}m`
  return `${seconds}s`
}

const NotificationFragment = graphql(`
  fragment Notification on DashboardNotification {
    member {
      id
      name
      accountDeactivationDate
    }
    event
    createdAt
    id
    seen
  }
`)

interface Props {
  notification: FragmentType<typeof NotificationFragment>
  handleClose: () => void
}

export const NotificationItem = forwardRef<HTMLLIElement | null, Props>(
  (props, ref) => {
    const notification = useFragment(NotificationFragment, props.notification)
    const navigate = useNavigate()

    const handleClick = async () => {
      navigate(`/peer-portal/members/${notification.member.id}`)
      props.handleClose()
    }

    let eventText = ''

    switch (notification.event) {
      case DashboardNotificationEvent.AccountDeleted:
        eventText = 'Has deleted their account.'
        break
      case DashboardNotificationEvent.PaymentFailed:
        eventText = 'Payment method has failed.'
        break
      case DashboardNotificationEvent.PeerAssigned:
        eventText = 'You have a new member!'
        break
      case DashboardNotificationEvent.SubscriptionCanceled:
        eventText = 'Has canceled their subscription.'
        break
      case DashboardNotificationEvent.AccountDeactivated:
        eventText = `Account will be deactivated on ${
          notification.member.accountDeactivationDate?.toFormat('MM/dd/yy') ??
          'N/A'
        }.`
        break
      case DashboardNotificationEvent.GoalCreated:
        eventText = 'Has created a new goal.'
        break
      case DashboardNotificationEvent.GoalDeleted:
        eventText = 'Has deleted a goal.'
        break
    }

    return (
      <ListItem
        ref={ref}
        sx={{
          cursor: 'pointer',
          '&:hover': { bgcolor: t => t.palette.grey[100] },
        }}
        onClick={() => handleClick()}
        key={notification.id}>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center">
          <Box width={35}>
            {!notification.seen && <Circle fontSize="small" color="error" />}
          </Box>
          <Box display="flex" flexDirection="column" overflow="auto" flex={1}>
            <Typography fontWeight={600} variant="body1">
              {notification.member.name
                ? `${notification.member.name} (ID: ${notification.member.id})`
                : `Member ${notification.member.id}`}
            </Typography>
            <Typography variant="body2">{eventText}</Typography>
          </Box>
          <Box>
            <Typography color="GrayText" variant="subtitle2">
              {timestamp(notification.createdAt)}
            </Typography>
          </Box>
        </Box>
      </ListItem>
    )
  },
)
