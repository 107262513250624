import { Box, Grid, Typography, useTheme } from '@mui/material'

import { UpdatePasswordForm } from './UpdatePasswordForm'

export const Settings = () => {
  const theme = useTheme()

  return (
    <Box padding={theme.spacing(5)}>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <Typography variant="h3">Password</Typography>
          <UpdatePasswordForm />
        </Grid>
      </Grid>
    </Box>
  )
}
