import './index.css'
import '@sendbird/uikit-react/dist/index.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import { ApolloProvider } from '@apollo/client'
import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { SnackbarProvider } from 'notistack'
import React, { Profiler } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { client } from 'src/apollo'
import { logProfiler } from 'src/common/logProfiler'
import { AppRoutes } from 'src/routes'
import { store } from 'src/store'
import { theme } from 'src/styles/theme'

const APP_SERVER_PROXY_URL = `${import.meta.env.VITE_SERVER_URL}/proxy`

axios.defaults.baseURL = APP_SERVER_PROXY_URL
axios.interceptors.response.use(
  res => res,
  error => {
    if (error.code === 'ECONNABORTED')
      return new Promise((_, reject) => {
        setTimeout(() => reject(error), 1000)
      })

    return Promise.reject(error)
  },
)

export const App = () => (
  <Profiler id="App" onRender={logProfiler}>
    <React.StrictMode>
      <Sentry.ErrorBoundary>
        <Provider store={store}>
          <ApolloProvider client={client}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <SnackbarProvider maxSnack={3}>
                <>
                  <CssBaseline />
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                      <BrowserRouter>
                        <AppRoutes />
                      </BrowserRouter>
                    </ThemeProvider>
                  </StyledEngineProvider>
                </>
              </SnackbarProvider>
            </LocalizationProvider>
          </ApolloProvider>
        </Provider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  </Profiler>
)
